import React, { useMemo } from 'react';
import loadable from '@loadable/component';
import { gql } from '@apollo/client';
import { DIVIDER_PUMO_MODULE_FRAGMENT } from '../DividerPumoModule';
import { SINGLE_PUMO_MODULE_FRAGMENT } from '../SinglePumoModule';
import { MULTI_PUMO_MODULE_FRAGMENT } from '../MultiPumoModule';
import { HOMEPAGE_HEADER_PUMO_MODULE_FRAGMENT } from '../HomePageHeaderPumoModule';
import { CAROUSEL_PUMO_MODULE_FRAGMENT } from '../CarouselPumoModule';
import { RECOMMENDATIONS_PUMO_MODULE_FRAGMENT } from '../RecommendationsPumoModule';
import { EMAIL_PUMO_MODULE_FRAGMENT } from '../EmailPumoModule';
import { PILLS_PUMO_MODULE_FRAGMENT } from '../PillsPumoModule';
import { TRUNKSHOW_COUNTDOWN_PUMO_MODULE_FRAGMENT } from '../TrunkshowCountdownPumoModule';
import { useAdminMode } from '../AdminMode/AdminModeProvider';
import { PumoModuleFragment } from '../../generated/types';
import { SMS_PUMO_MODULE_FRAGMENT } from '../SmsPumoModule';
import { VIDEO_PUMO_MODULE_FRAGMENT } from '../VideoPumoModule';
import { GRID_PUMO_MODULE_FRAGMENT } from '../GridPumoModule';

const DividerPumoModule = loadable(() => import('../DividerPumoModule'));
const SinglePumoModule = loadable(() => import('../SinglePumoModule'));
const MultiPumoModule = loadable(() => import('../MultiPumoModule'));
const HomePageHeaderPumoModule = loadable(() => import('../HomePageHeaderPumoModule'));
const CarouselPumoModule = loadable(() => import('../CarouselPumoModule'));
const RecommendationsPumoModule = loadable(() => import('../RecommendationsPumoModule'));
const EmailPumoModule = loadable(() => import('../EmailPumoModule'));
const PillsPumoModule = loadable(() => import('../PillsPumoModule'));
const TrunkshowCountdownPumoModule = loadable(() => import('../TrunkshowCountdownPumoModule'));
const SmsPumoModule = loadable(() => import('../SmsPumoModule'));
const VideoPumoModule = loadable(() => import('../VideoPumoModule'));
const GridPumoModule = loadable(() => import('../GridPumoModule'));
const AdminPumoModuleOverlay = loadable(() => import('../AdminMode/AdminModePumoModuleOverlay'));

export const PUMO_MODULE_FRAGMENT = gql`
  fragment PumoModuleFragment on PumoModule {
    __typename
    ... on LivestreamPumoModule {
      id
      modelId
      name
    }
    ... on DividerPumoModule {
      id
      modelId
    }
    ... on SinglePumoModule {
      id
      modelId
    }
    ... on MultiPumoModule {
      id
      modelId
    }
    ... on CarouselPumoModule {
      id
      modelId
    }
    ... on RecommendationsPumoModule {
      id
      modelId
    }
    ... on HomePageHeaderPumoModule {
      id
      modelId
    }
    ... on EmailPumoModule {
      id
      modelId
    }
    ... on PillsPumoModule {
      id
      modelId
    }
    ... on TrunkshowCountdownPumoModule {
      id
      modelId
    }
    ... on SmsPumoModule {
      id
      modelId
    }
    ... on VideoPumoModule {
      id
      modelId
    }
    ... on GridPumoModule {
      id
      modelId
    }
    ...CarouselPumoModuleFragment
    ...DividerPumoModuleFragment
    ...EmailPumoModuleFragment
    ...HomePageHeaderPumoModuleFragment
    ...MultiPumoModuleFragment
    ...PillsPumoModuleFragment
    ...RecommendationsPumoModuleFragment
    ...SinglePumoModuleFragment
    ...TrunkshowCountdownPumoModuleFragment
    ...SmsPumoModuleFragment
    ...VideoPumoModuleFragment
    ...GridPumoModuleFragment
  }
  ${CAROUSEL_PUMO_MODULE_FRAGMENT}
  ${DIVIDER_PUMO_MODULE_FRAGMENT}
  ${EMAIL_PUMO_MODULE_FRAGMENT}
  ${HOMEPAGE_HEADER_PUMO_MODULE_FRAGMENT}
  ${MULTI_PUMO_MODULE_FRAGMENT}
  ${PILLS_PUMO_MODULE_FRAGMENT}
  ${RECOMMENDATIONS_PUMO_MODULE_FRAGMENT}
  ${SINGLE_PUMO_MODULE_FRAGMENT}
  ${TRUNKSHOW_COUNTDOWN_PUMO_MODULE_FRAGMENT}
  ${SMS_PUMO_MODULE_FRAGMENT}
  ${VIDEO_PUMO_MODULE_FRAGMENT}
  ${GRID_PUMO_MODULE_FRAGMENT}
`;

interface Props {
  module: PumoModuleFragment;
}

export const PumoModule: React.FC<Props> = ({ module }) => {
  const content = useMemo(() => {
    switch (module.__typename) {
      case 'DividerPumoModule':
        return <DividerPumoModule dividerPumoModule={module} />;
      case 'SinglePumoModule':
        return <SinglePumoModule singlePumoModule={module} />;
      case 'MultiPumoModule':
        return <MultiPumoModule multiPumoModule={module} />;
      case 'HomePageHeaderPumoModule':
        return <HomePageHeaderPumoModule homePageHeaderPumoModule={module} />;
      case 'CarouselPumoModule':
        return <CarouselPumoModule carouselPumoModule={module} />;
      case 'RecommendationsPumoModule':
        return <RecommendationsPumoModule recommendationsPumoModule={module} />;
      case 'EmailPumoModule':
        return <EmailPumoModule emailPumoModule={module} />;
      case 'PillsPumoModule':
        return <PillsPumoModule pillsPumoModule={module} />;
      case 'TrunkshowCountdownPumoModule':
        return <TrunkshowCountdownPumoModule trunkshowCountdownPumoModule={module} />;
      case 'SmsPumoModule':
        return <SmsPumoModule smsPumoModule={module} />;
      case 'VideoPumoModule':
        return <VideoPumoModule videoModule={module} />;
      case 'GridPumoModule':
        return <GridPumoModule gridPumoModule={module} />;
      default:
        return null;
    }
  }, [module]);

  const { enabled: adminModeEnabled } = useAdminMode();

  if (!content) return null;

  if (adminModeEnabled) {
    return (
      <AdminPumoModuleOverlay fallback={content} modelId={module.modelId}>
        {content}
      </AdminPumoModuleOverlay>
    );
  }

  return content;
};
