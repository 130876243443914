import React from 'react';
import { gql } from '@apollo/client';
import { Stack, Text, useBreakpoint } from '@moda/om';
import { GridPumoModuleFragment, PumoModuleSlideFragment } from '../../generated/types';
import { useModuleTracking } from '../../hooks/useModuleTracking';
import { PUMO_MODULE_SLIDE_FRAGMENT, PumoModuleSlide } from '../PumoModuleSlide';
import { RestrictPlatform } from '../RestrictPlatform';
import { NormalizePumoStyles } from '../NormalizePumoStyles';
import { EDITORIAL_BG_COLORS, EditorialBgColor } from '../PumoModuleEditorialText';

import './GridPumoModule.scss';

export const GRID_PUMO_MODULE_FRAGMENT = gql`
  fragment GridPumoModuleFragment on GridPumoModule {
    name
    bgColor
    platforms: platform
    header
    subheader
    editorials {
      slides {
        ...PumoModuleSlideFragment
      }
    }
    moduleType
    modelId
  }
  ${PUMO_MODULE_SLIDE_FRAGMENT}
`;

const NUMBER_OF_MOBILE_COLUMNS = 2;
const NUMBER_OF_DESKTOP_COLUMNS = 3;

const getColumns = (slides: PumoModuleSlideFragment[], numberOfColumns = 3) => {
  const columns: PumoModuleSlideFragment[][] = [];

  slides.forEach((slide, index) => {
    if (index < numberOfColumns) return columns.push([slide]);

    columns[
      ((index % numberOfColumns) + numberOfColumns - (slides.length % numberOfColumns)) %
        numberOfColumns
    ].push(slide);
  });

  return columns;
};

type Props = {
  gridPumoModule: GridPumoModuleFragment;
};

export const GridPumoModule: React.FC<Props> = ({
  gridPumoModule: { name, bgColor, platforms, modelId, moduleType, header, subheader, editorials }
}) => {
  const { ref } = useModuleTracking({ name });
  const isMobile = useBreakpoint({ lt: 'md' }).matches;

  const slides = (editorials ?? []).map(editorial => editorial.slides).flat();
  const columns = getColumns(
    slides,
    isMobile ? NUMBER_OF_MOBILE_COLUMNS : NUMBER_OF_DESKTOP_COLUMNS
  );

  return (
    <RestrictPlatform platforms={platforms}>
      <div ref={ref}>
        <NormalizePumoStyles
          className="GridPumoModule"
          style={{ backgroundColor: EDITORIAL_BG_COLORS[bgColor as EditorialBgColor] }}
          data-model-id={modelId}
          data-module-type={moduleType}
        >
          <Stack space={2}>
            {header && (
              <Text className="GridPumoModule__header" treatment="h4" family="serif">
                {header}
              </Text>
            )}
            {subheader && (
              <Text className="GridPumoModule__subheader" treatment="h6" family="serif">
                {subheader}
              </Text>
            )}
          </Stack>
          <div className="GridPumoModule__grid">
            {columns.map(column => (
              <div key={column[0].id} className="GridPumoModule__column">
                {column.map(slide => (
                  <PumoModuleSlide key={slide.id} index={slides.indexOf(slide) + 1} slide={slide} />
                ))}
              </div>
            ))}
          </div>
        </NormalizePumoStyles>
      </div>
    </RestrictPlatform>
  );
};
