import { useBreakpoint } from '@moda/om';

export const useVideoIconSizeProps = (mobileSizeProps: { lt: string }) => {
  const { matches: isMobile } = useBreakpoint(mobileSizeProps);

  if (isMobile) {
    return {
      width: '40px',
      height: '40px'
    };
  }

  return {
    width: '30px',
    height: '30px'
  };
};
