import React, { useMemo } from 'react';
import { gql } from '@apollo/client';
import { formatPrice } from '@moda/portal-stanchions';
import { VariantCellProductPriceFragment } from '../../../../../generated/types';
import { getActiveVariantBy } from '../../../../../lib/getActiveVariantBy';
import { getRoundedMarkdownPercent } from '../../../../../lib/variantUtils/getRoundedMarkdownPercent';

import './VariantCellProductPrice.scss';

export const VARIANT_CELL_PRODUCT_PRICE_VARIANT_FRAGMENT = gql`
  fragment VariantCellProductPriceVariantFragment on VariantData {
    id
    payInFull: pay_in_full
    prices(country_code: $countryCode) {
      currentPrice: current_price {
        price
        currency
      }
      originalPrice: original_price {
        price
        currency
      }
    }
  }
`;

export const VARIANT_CELL_PRODUCT_PRICE_FRAGMENT = gql`
  fragment VariantCellProductPriceFragment on Variant {
    id
    masterVariant: master_variants_data {
      ...VariantCellProductPriceVariantFragment
    }
    otherVariants: other_variants_data {
      ...VariantCellProductPriceVariantFragment
    }
  }
  ${VARIANT_CELL_PRODUCT_PRICE_VARIANT_FRAGMENT}
`;

interface Props {
  variant: VariantCellProductPriceFragment;
  activeVariantId: string;
  showSaleSavings?: boolean;
  children?: React.ReactNode;
}

export const VariantCellProductPrice: React.FC<Props> = ({
  variant,
  activeVariantId,
  children
}) => {
  const {
    prices: { currentPrice, originalPrice }
  } = useMemo(() => getActiveVariantBy(variant, 'id', activeVariantId), [activeVariantId, variant]);

  const showOriginalPrice =
    Boolean(originalPrice) && Boolean(currentPrice) && originalPrice?.price !== currentPrice?.price;

  const markdownPercent = getRoundedMarkdownPercent({
    currentPrice: parseFloat(currentPrice?.price || '0.0'),
    originalPrice: parseFloat(originalPrice?.price || '0.0')
  });

  return (
    <div className="VariantCellProductPrice">
      <div className="VariantCellProductPrice__wrapper">
        {showOriginalPrice && originalPrice?.price && (
          <span className="VariantCellProductPrice__original">
            {formatPrice(originalPrice.currency, originalPrice.price, { overrideFraction: true })}
          </span>
        )}

        {currentPrice?.price && (
          <span className="VariantCellProductPrice__current-price">
            {formatPrice(currentPrice.currency, currentPrice.price, { overrideFraction: true })}
          </span>
        )}

        {showOriginalPrice && markdownPercent > 0 && (
          <span className="VariantCellProductPrice__markdown-percent">Save {markdownPercent}%</span>
        )}
      </div>
      {children && <div>{children}</div>}
    </div>
  );
};
