import { BrightcovePlayer } from '@brightcove/player-loader';
import { SyntheticEvent, useCallback, useMemo, useState } from 'react';

const DEFAULT_MUTED = true;

type VideoPlayerOptions = {
  hasAudio?: boolean;
  isMobile: boolean;
};

export const useVideoPlayer = ({ hasAudio, isMobile }: VideoPlayerOptions) => {
  const [player, setPlayer] = useState<null | BrightcovePlayer>(null);
  const [isPlaying, setPlaying] = useState(false);
  const [isPaused, setPaused] = useState(false);
  const [loading, setLoading] = useState(false);
  const [muted, setMuted] = useState(DEFAULT_MUTED);
  const [isHovered, setHovered] = useState(false);

  const initialize = useCallback(
    (brightcovePlayer: BrightcovePlayer) => {
      brightcovePlayer?.on('playing', () => {
        setPlaying(true);
        setPaused(false);
      });

      brightcovePlayer?.on('pause', () => {
        setPlaying(false);
        setPaused(true);
      });

      brightcovePlayer?.on('loadstart', () => {
        setLoading(true);
      });

      brightcovePlayer?.on('loadeddata', () => {
        setLoading(false);
      });

      brightcovePlayer.muted(muted);

      if (brightcovePlayer.paused()) setPaused(true);

      setPlayer(brightcovePlayer);
    },
    [muted]
  );

  const handleMute = useCallback(
    (event: SyntheticEvent) => {
      event.preventDefault();

      event.stopPropagation();

      if (!player) return;

      if (!hasAudio) return;

      player.muted(!muted);
      setMuted(!muted);
    },
    [player, hasAudio, muted]
  );

  const showControls = useMemo(() => {
    if (loading) return false;

    if (!player) return false;

    if (isHovered) return true;

    if (isPaused) return true;

    if (isMobile) return true;

    return false;
  }, [isHovered, isMobile, isPaused, loading, player]);

  return {
    initialize,
    player,
    isPlaying,
    loading,
    muted,
    handleMute,
    isHovered,
    setHovered,
    showControls
  };
};
