import { useCallback, useEffect, useRef } from 'react';
import composeRefs from '@seznam/compose-react-refs';
import { useInView } from 'react-intersection-observer';
import { tc } from '../lib/trackingContext';
import { CarouselCellFragment, VariantCellFragment } from '../generated/types';

export const useModuleTracking = ({
  name,
  attachClickHandler = true,
  variants,
  variantId = ''
}: {
  name: string | null;
  attachClickHandler?: boolean;
  variants?: VariantCellFragment[] | CarouselCellFragment[];
  variantId?: string;
}) => {
  const { ref, inView } = useInView({ threshold: 0.5, triggerOnce: true });

  const containerRef = useRef<HTMLDivElement>(null);

  const sibling = containerRef.current?.previousElementSibling;
  const prevDividerModuleName =
    sibling && sibling.classList.contains('DividerModule') ? sibling.textContent : '';

  const onClick = useCallback(
    (event: Event | React.MouseEvent) => {
      const clickedAnchor = (event.target as HTMLElement).closest('a');
      if (containerRef.current && containerRef.current.contains(clickedAnchor)) {
        tc.track('Module Clicked', {
          addToScopeOnce: {
            moduleName: name || prevDividerModuleName
          },
          addToTrackingEvent: {
            label: name || prevDividerModuleName,
            nonInteraction: 1,
            page: window.location.pathname,
            coordinateX: (event as MouseEvent)?.pageX || 0,
            coordinateY: (event as MouseEvent)?.pageY || 0
          }
        });
      }
    },
    [prevDividerModuleName, name]
  );

  useEffect(() => {
    const current = containerRef.current;
    if (attachClickHandler) {
      current?.addEventListener('mousedown', onClick);
      return () => {
        current?.removeEventListener('mousedown', onClick);
      };
    }
  }, [onClick, attachClickHandler]);

  useEffect(() => {
    if (inView) {
      tc.track('Module Viewed', {
        addToScopeOnce: {
          moduleName: name || prevDividerModuleName,
          products: variants
        },
        addToTrackingEvent: {
          nonInteraction: 1,
          label: name || prevDividerModuleName,
          variantId: variantId
        }
      });
    }
  }, [inView, name, prevDividerModuleName, variantId, variants]);

  return { ref: composeRefs<HTMLDivElement>(ref, containerRef), onClick };
};
