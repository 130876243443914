import { useCallback, useEffect, useState, useMemo } from 'react';
import { useMutation, useApolloClient, gql } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { Availability, compact, ONE_YEAR_SECONDS } from '@moda/portal-stanchions';
import { logger } from '../../lib/logger';
import { reportError } from '../../lib/reportError';
import { getActiveVariantBy } from '../../lib/getActiveVariantBy';
import { tc } from '../../lib/trackingContext';
import { AddToBagFragment, AddToBagInventoryFragment } from '../../generated/types';
import { useCountry } from '../../components/CountrySelector';
import { useCartVersion, CartVersion } from '../useCart';
import { GET_CART_QUERY } from '../useCart/cart.rest';
import { useCookies } from '../useCookies';
import { useCJCart } from '../useCjCart';
import { ADD_TO_BAG_MUTATION } from './addToBag.rest';
import { trackAddToBag } from './trackAddToBag';

export enum AddToBagMode {
  Resting = 'Add To Bag',
  SizeRequired = 'Select a Size',
  PersonalizationRequired = 'Enter a Personalized Message',
  Pending = 'Adding to Bag',
  Added = 'Item Added to Bag',
  Error = 'Error Adding to Bag'
}

export type UseAddToBagProps = {
  activeVariantId: string;
  customization?: string;
  isCustomizationValid?: boolean;
  validatesSizePresenceOn: 'submit' | 'always';
  href?: string;
  position?: number;
  size?: AddToBagInventoryFragment | null;
  quantity?: number;
  variant: AddToBagFragment;
  onAdded?: () => void;
};

export const ADD_TO_BAG_INVENTORY_FRAGMENT = gql`
  fragment AddToBagInventoryFragment on Inventory {
    size
    canSell: can_sell
  }
`;

export const ADD_TO_BAG_FRAGMENT = gql`
  fragment AddToBagFragment on Variant {
    id
    availability
    category
    customizable
    productId: product_id
    objectid
    index
    queryid
    trunkshowsData: limited_trunkshow_data {
      id
    }
    masterVariant: master_variants_data {
      inventory {
        ...AddToBagInventoryFragment
      }
    }
    otherVariants: other_variants_data {
      inventory {
        ...AddToBagInventoryFragment
      }
    }
    subcategory
  }
  ${ADD_TO_BAG_INVENTORY_FRAGMENT}
`;

const SHOW_ADDED_MS = 1000;
const SHOW_ERROR_MS = 2000;

const getButtonText = (
  variant: AddToBagFragment,
  size: AddToBagInventoryFragment | null,
  mode: AddToBagMode
): AddToBagMode | 'Sold Out' | 'Preorder' => {
  if (size && !size.canSell) {
    return 'Sold Out';
  }

  if (
    mode === AddToBagMode.Resting &&
    (Number(variant.availability) === Availability.Trunkshow ||
      Number(variant.availability) === Availability.Preorder)
  ) {
    return 'Preorder';
  }

  return mode;
};

export const useAddToBag = ({
  activeVariantId,
  customization,
  isCustomizationValid,
  href,
  position = 1,
  size = null,
  quantity = 1,
  variant,
  validatesSizePresenceOn,
  onAdded
}: UseAddToBagProps) => {
  const [mode, setMode] = useState<AddToBagMode>(AddToBagMode.Resting);

  const activeVariant = useMemo(
    () => getActiveVariantBy(variant, 'id', activeVariantId),
    [variant, activeVariantId]
  );

  useEffect(() => {
    if (!size && validatesSizePresenceOn === 'always') setMode(AddToBagMode.SizeRequired);
  }, [size, validatesSizePresenceOn]);

  const { push } = useHistory();
  const { country } = useCountry();
  const { addToCJCart } = useCJCart();
  const {
    cookies: { cartId },
    setCookie
  } = useCookies();
  const client = useApolloClient();
  const cartVersion = useCartVersion();

  const [addToBag, { loading }] = useMutation(ADD_TO_BAG_MUTATION, {
    onCompleted: data => {
      onAdded?.();
      setMode(AddToBagMode.Added);
      const addedLineItem = data?.addToBag?.data?.[0]?.attributes?.cartItems?.find(
        cartItem => `${cartItem?.data?.attributes?.variantId}` == activeVariantId
      );
      if (addedLineItem?.data?.attributes?.skuId) addToCJCart(addedLineItem.data.attributes.skuId);
      trackAddToBag({ activeVariantId, data, size, variant });
    },
    onError: error => {
      reportError('ADD_TO_BAG', error);
      setMode(AddToBagMode.Error);
    },
    update: (cache, { data }) => {
      if (!data) return;

      const newCartId = data.addToBag.data[0].id;

      if (!newCartId) return;

      if (!cartId) {
        setCookie('cartId', newCartId, ONE_YEAR_SECONDS);
      }
      tc.setCurrent('cart', data.addToBag.data[0]);

      cache.writeQuery({
        query: GET_CART_QUERY,
        variables: { cartId: newCartId, cartVersion, countryCode: country.alpha2Code },
        data: { userCart: { ...data.addToBag, __typename: 'RestUserCart' } }
      });
    }
  });

  const handleAddToBag = useCallback(async () => {
    if (!size?.size) {
      if (mode === AddToBagMode.Resting) {
        setMode(AddToBagMode.SizeRequired);
      }

      return;
    }

    if (!activeVariantId) {
      logger.warn('ADD_TO_BAG', 'Unable to add to bag: `activeVariantId` is missing');
      return;
    }

    if (variant.customizable && href) {
      return push(`${href}?size=${size.size}`);
    }

    if (variant.customizable && !isCustomizationValid) {
      if (mode === AddToBagMode.Resting) {
        setMode(AddToBagMode.PersonalizationRequired);
      }

      return;
    }

    setMode(AddToBagMode.Pending);

    if (cartId) {
      // make sure the cart is fetched before running the mutation
      await client.query({
        query: GET_CART_QUERY,
        variables: { cartId, cartVersion, countryCode: country.alpha2Code }
      });
    }

    addToBag({
      variables: {
        input: {
          data: {
            attributes: {
              availability: variant.availability,
              cart_id: cartId,
              country_code: country.alpha2Code,
              customization: customization || '',
              position,
              product_id: variant.productId,
              size: size.size,
              quantity,
              target: CartVersion.MiniBag,
              trunkshow_id: variant.trunkshowsData?.[0]?.id || null,
              variant_id: activeVariantId
            },
            type: 'line_items'
          }
        }
      }
    });
  }, [
    activeVariantId,
    addToBag,
    cartId,
    cartVersion,
    client,
    country.alpha2Code,
    customization,
    isCustomizationValid,
    href,
    mode,
    position,
    push,
    size,
    quantity,
    variant
  ]);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (mode === AddToBagMode.Added) {
      timeout = setTimeout(() => {
        setMode(size ? AddToBagMode.Resting : AddToBagMode.SizeRequired);
      }, SHOW_ADDED_MS);
    }

    return () => clearTimeout(timeout);
  }, [mode, size, variant]);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;

    if (mode === AddToBagMode.Error) {
      timeout = setTimeout(
        () => setMode(size ? AddToBagMode.Resting : AddToBagMode.SizeRequired),
        SHOW_ERROR_MS
      );
    }

    return () => clearTimeout(timeout);
  }, [mode, size, variant]);

  useEffect(() => {
    if (size && mode === AddToBagMode.SizeRequired) {
      setMode(AddToBagMode.Resting);
    }
  }, [mode, size, variant]);

  useEffect(() => {
    if (isCustomizationValid && mode === AddToBagMode.PersonalizationRequired) {
      setMode(AddToBagMode.Resting);
    }
  }, [isCustomizationValid, mode]);

  const text = getButtonText(variant, size, mode);

  const inventory = compact(activeVariant.inventory);
  const isOneSizedItem = inventory.length === 1 && inventory[0].size === 'OS';

  return {
    added: mode === AddToBagMode.Added,
    disabled: mode !== AddToBagMode.Resting || text === 'Sold Out',
    error: mode === AddToBagMode.Error,
    handleAddToBag,
    loading,
    text,
    isOneSizedItem
  };
};
