import { gql, TypedDocumentNode } from '@apollo/client';
import { CART_FRAGMENT, Cart } from '../useCart/cart.rest';
import { Item } from '../useAddSetToBag';

export type AddSetToBagMutationVariables = {
  input: {
    data: {
      attributes: {
        items: Item[];
      };
      type: 'line_items';
    };
  };
};

export type AddSetToBagMutation = {
  addSetToBag: {
    data: Cart[];
  };
};

export const ADD_SET_TO_BAG_MUTATION: TypedDocumentNode<
  AddSetToBagMutation,
  AddSetToBagMutationVariables
> = gql`
  mutation AddSetToBagMutation($input: AddSetToBagInput) {
    addSetToBag(input: $input)
      @rest(
        type: "RestAddSetToBag"
        endpoint: "moda"
        path: "/v3.5/line_items/create_multi"
        method: "POST"
      ) {
      data @type(name: "RestUserCartData") {
        ...CartFragment
      }
    }
  }
  ${CART_FRAGMENT}
`;
