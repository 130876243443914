import { useApolloClient, useMutation, DataProxy, gql } from '@apollo/client';
import { useCallback, useMemo, useContext, useEffect } from 'react';
import debounce from 'debounce';
import { includes } from 'ramda';
import { FavoriteFragment } from '../../generated/types';
import { logger } from '../../lib/logger';
import { tc } from '../../lib/trackingContext';
import { USER_QUERY } from '../../hooks/useUser';
import { FavoritesContext } from './FavoritesProvider';
import {
  ADD_FAVORITE_MUTATION,
  GET_FAVORITES_QUERY,
  REMOVE_FAVORITE_MUTATION,
  GetFavoritesQuery
} from './favorites.rest';

export type UseFavoritesProps = {
  variant?: FavoriteFragment;
};

export const defaults = {
  data: {
    __typename: 'RestUserFavoritesData',
    attributes: {
      __typename: 'RestUserFavoritesAttributes',
      variantIds: []
    }
  }
};

export const FAVORITE_FRAGMENT = gql`
  fragment FavoriteFragment on Variant {
    id
  }
`;

const DEBOUNCE_AMOUNT_MS = 400;

type UpdateFavoritesCacheAction = 'add' | 'remove';

const updateFavoritesCache = (
  cache: DataProxy,
  variantId: string,
  action: UpdateFavoritesCacheAction
) => {
  const userQueryData = cache.readQuery({ query: USER_QUERY });

  const userId = userQueryData?.user?.id;

  const data = userId
    ? cache.readQuery({ query: GET_FAVORITES_QUERY, variables: { userId } })
    : null;

  if (!data) {
    return;
  }

  const variantIds = data.userFavorites?.data.attributes.variants.map(({ id }) => id) || [];

  const getNewVariantIds = () => {
    if (action === 'remove') {
      return variantIds.filter((id: string) => id !== variantId);
    }

    if (action === 'add') {
      return [variantId, ...variantIds];
    }

    return variantIds;
  };

  const newVariantIds = getNewVariantIds();

  const resultData: GetFavoritesQuery = {
    userFavorites: {
      __typename: 'RestUserFavorites',
      data: {
        __typename: 'RestUserFavoritesData',
        attributes: {
          __typename: 'RestUserFavoritesAttributes',
          variants: newVariantIds.map(id => ({ id }))
        }
      }
    }
  };

  if (!userId) return;

  cache.writeQuery({
    query: GET_FAVORITES_QUERY,
    data: resultData,
    variables: { userId }
  });
};

export const useFavorites = ({ variant }: UseFavoritesProps = {}) => {
  const variantId = variant?.id || '';
  const client = useApolloClient();
  const { user, favorites, getFavorites, loaded } = useContext(FavoritesContext);

  useEffect(() => {
    getFavorites();
  }, [getFavorites]);

  const [addFavorite] = useMutation(ADD_FAVORITE_MUTATION, {
    update(cache) {
      updateFavoritesCache(cache, variantId, 'add');
    }
  });

  const [removeFavorite] = useMutation(REMOVE_FAVORITE_MUTATION, {
    update(cache) {
      updateFavoritesCache(cache, variantId, 'remove');
    }
  });

  const debouncedAddFavorite = useMemo(
    () => debounce(addFavorite, DEBOUNCE_AMOUNT_MS, { immediate: true }),
    [addFavorite]
  );

  const debouncedRemoveFavorite = useMemo(
    () => debounce(removeFavorite, DEBOUNCE_AMOUNT_MS, { immediate: true }),
    [removeFavorite]
  );

  const isFavorited = useMemo(() => includes(variantId, favorites), [favorites, variantId]);

  const handleFavorite = useCallback(async () => {
    if (!variantId) {
      logger.warn('HANDLE_FAVORITE', 'Unable to add/remove favorite: `variantId` is missing');
      return Promise.resolve();
    }

    const { data: userQueryData } = await client.query({ query: USER_QUERY });

    if (!userQueryData?.user) {
      // eslint-disable-next-line no-console
      console.warn('Unable to add/remove favorite: `user` is missing');
      return Promise.resolve();
    }

    const { id: userId, accessToken } = userQueryData.user;

    // make sure favorites are fetched before running the mutation
    await client.query({
      query: GET_FAVORITES_QUERY,
      variables: { userId },
      context: {
        headers: {
          'x-user-preferences-api-token': `Bearer ${accessToken}`
        }
      }
    });

    if (isFavorited) {
      await debouncedRemoveFavorite({ variables: { id: variantId } });

      tc.track('Product Removed from Wishlist', {
        addToScopeOnce: { variant },
        addToTrackingEvent: {
          label: 'Favorites',
          wishlist_name: 'Favorites'
        }
      });
    } else {
      await debouncedAddFavorite({ variables: { id: variantId } });

      tc.track('Product Added to Wishlist', {
        addToScopeOnce: { variant },
        addToTrackingEvent: {
          label: 'Favorites',
          wishlist_name: 'Favorites'
        }
      });
    }
  }, [variantId, client, isFavorited, debouncedRemoveFavorite, variant, debouncedAddFavorite]);

  return { user, favorites, handleFavorite, isFavorited, loaded };
};
