import { gql } from '@apollo/client';

const ADMIN_PRODUCT_CONTROLS_VARIANT_FRAGMENT = gql`
  fragment AdminProductControlsVariantFragment on VariantData {
    id
    primaryImageUrls: primary_image_urls {
      large
    }
    alternateImageUrls: alternate_image_urls {
      large
    }
  }
`;

export const ADMIN_PRODUCT_CONTROLS_FRAGMENT = gql`
  fragment AdminProductControlsFragment on Variant {
    id
    name
    productId: product_id
    designerName: designer_name
    masterVariant: master_variants_data {
      ...AdminProductControlsVariantFragment
    }
  }
  ${ADMIN_PRODUCT_CONTROLS_VARIANT_FRAGMENT}
`;
