import { gql } from '@apollo/client';
import React from 'react';
import { CarouselPumoModuleSliderFragment } from '../../generated/types';
import { CarouselCell, CAROUSEL_CELL_FRAGMENT } from '../Grid';
import { CAROUSEL_DEFAULT_CONFIG, ProductSwiperCarousel, SwiperCarouselProps } from '../Swiper';

import './CarouselPumoModuleSlider.scss';

export const CAROUSEL_PUMO_MODULE_SLIDER_FRAGMENT = gql`
  fragment CarouselPumoModuleSliderFragment on CarouselPumoModule {
    id
    cells {
      ...CarouselCellFragment
    }
  }
  ${CAROUSEL_CELL_FRAGMENT}
`;

interface Props {
  carouselPumoModuleSlider: CarouselPumoModuleSliderFragment;
  onClick?: (event: Event) => void;
  moduleName: string | null;
  config?: Partial<SwiperCarouselProps>;
}

export const CarouselPumoModuleSlider: React.FC<Props> = ({
  carouselPumoModuleSlider,
  onClick,
  moduleName,
  config
}) => (
  <ProductSwiperCarousel
    {...CAROUSEL_DEFAULT_CONFIG}
    {...config}
    identifier={`CarouselPumoModuleSlider-${carouselPumoModuleSlider.id}`}
    onClick={(swiper, event) => onClick?.(event)}
  >
    {carouselPumoModuleSlider.cells.map((carouselCell, index) => (
      <CarouselCell key={index} carouselCell={carouselCell} moduleName={moduleName} index={index} />
    ))}
  </ProductSwiperCarousel>
);
