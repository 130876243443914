import React from 'react';
import { Link } from '../Link';
import './CallToAction.scss';

interface Props {
  href?: string;
  children?: React.ReactNode;
}

export const CallToAction: React.FC<Props> = ({ href, children }) => {
  return href ? (
    <Link to={href} className="CallToAction">
      <span>{children}</span>
    </Link>
  ) : (
    <div className="CallToAction">
      <span>{children}</span>
    </div>
  );
};
