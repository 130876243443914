import React from 'react';
import { gql } from '@apollo/client';
import { Stack, Text } from '@moda/om';
import { composeImageSrc } from '@moda/portal-stanchions';
import { CategoryCellFragment } from '../../generated/types';
import { CONFIG } from '../../config';
import { Link } from '../Link';
import { Image } from '../Image';
import './CategoryCell.scss';

export const CATEGORY_CELL_FRAGMENT = gql`
  fragment CategoryCellFragment on Slide {
    id
    ctaUrl
    featureImage {
      imageSrc: image
    }
    slideText {
      ctaText
    }
  }
`;

type Props = {
  cell: CategoryCellFragment;
};

export const CategoryCell: React.FC<Props> = ({ cell: { ctaUrl, featureImage, slideText } }) => {
  if (!featureImage || !slideText?.ctaText) return null;

  const imageSrc = composeImageSrc(featureImage.imageSrc, { origin: CONFIG.MODA_IMG_ROOT });

  return (
    <Link className="CategoryCell" aria-label={slideText.ctaText} to={ctaUrl || '/'}>
      <Stack space={3} alignItems="center">
        <Image src={imageSrc} alt={slideText.ctaText} width="360" height="640" />
        <Text treatment="h5">{slideText.ctaText}</Text>
      </Stack>
    </Link>
  );
};
