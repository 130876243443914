import React from 'react';
import { PumoModuleFragment } from '../../generated/types';
import { PumoModule } from '../PumoModule';

export interface Props {
  modules: PumoModuleFragment[];
}

export const ModuleSection: React.FC<Props> = ({ modules }) => (
  <section className="ModuleSection">
    {modules.map(module => (
      <PumoModule key={`${module.__typename}:${module.id}`} module={module} />
    ))}
  </section>
);
