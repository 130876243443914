import React from 'react';
import { space } from '@moda/tokens';
import { Clickable } from '@moda/om';
import ChevronLeftIcon from '@moda/icons/chevron-left-20';
import ChevronRightIcon from '@moda/icons/chevron-right-20';
import './CarouselArrow.scss';

interface Props {
  disabled: boolean;
  handleClick: () => void;
  direction: 'left' | 'right';
}

const LARGE_SIZE = 7;
const ARROW_SIZE = space.scale[LARGE_SIZE];

export const CarouselArrow: React.FC<Props> = ({ disabled, handleClick, direction }) => {
  const label = `Show the ${direction === 'left' ? 'previous' : 'next'} slide`;

  return (
    <Clickable
      aria-label={label}
      className={`CarouselArrow CarouselArrow--${direction}`}
      disabled={disabled}
      onClick={handleClick}
    >
      {direction === 'left' && (
        <ChevronLeftIcon className="CarouselArrow__icon" width={ARROW_SIZE} height={ARROW_SIZE} />
      )}

      {direction === 'right' && (
        <ChevronRightIcon className="CarouselArrow__icon" width={ARROW_SIZE} height={ARROW_SIZE} />
      )}
    </Clickable>
  );
};
