import React from 'react';
import classNames from 'classnames';
import { CarouselDot } from './CarouselDot';
import './CarouselDots.scss';

export interface Props {
  length: number;
  cursor: number;
  onClick(index: number): void;
  className?: string;
}

export const CarouselDots: React.FC<Props> = ({ className, length, cursor, onClick, ...rest }) => (
  <nav className={classNames('CarouselDots', className)} {...rest}>
    {new Array(length).fill(undefined).map((_, index) => (
      <CarouselDot key={index} index={index} onClick={onClick} active={cursor === index} />
    ))}
  </nav>
);
