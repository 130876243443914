import { gql } from '@apollo/client';
import { Availability } from '@moda/portal-stanchions';
import { color } from '@moda/tokens';
import { AdminModeProductInfoColorFragment } from '../../../generated/types';
import { useAdminMode } from '../AdminModeProvider';

export const ADMIN_MODE_PRODUCT_INFO_COLOR_FRAGMENT = gql`
  fragment AdminModeProductInfoColorFragment on Variant {
    id
    availability
    sellThroughAllTime: calculated_sell_through_all_time
  }
`;

const MIN_OPACITY = 0.1;
const MAX_OPACITY = 1;

export const useAdminModeProductInfoColor = ({
  availability,
  sellThroughAllTime
}: AdminModeProductInfoColorFragment) => {
  const { enabled } = useAdminMode();

  if (!enabled) return;
  if (sellThroughAllTime === 0) return color('fog');
  if (availability === String(Availability.Trunkshow)) return color('strawberry');

  return color('money-good', Math.min(Math.max(MIN_OPACITY, sellThroughAllTime), MAX_OPACITY));
};
