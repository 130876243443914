import React from 'react';
import { gql } from '@apollo/client';
import { Constrain } from '@moda/om';
import { CarouselCell, CAROUSEL_CELL_FRAGMENT, LazyGrid } from '../Grid';
import { CarouselPumoModuleGridFragment } from '../../generated/types';

export const CAROUSEL_PUMO_MODULE_GRID_FRAGMENT = gql`
  fragment CarouselPumoModuleGridFragment on CarouselPumoModule {
    cells {
      ...CarouselCellFragment
    }
  }
  ${CAROUSEL_CELL_FRAGMENT}
`;

interface Props {
  carouselPumoModuleGrid: CarouselPumoModuleGridFragment;
  onClick?: (event: Event | React.MouseEvent) => void;
}

export const CarouselPumoModuleGrid: React.FC<Props> = ({ carouselPumoModuleGrid, onClick }) => (
  <Constrain>
    <LazyGrid onClick={onClick}>
      {carouselPumoModuleGrid.cells.map((cell, index) => (
        <CarouselCell key={index} index={index} carouselCell={cell} />
      ))}
    </LazyGrid>
  </Constrain>
);
