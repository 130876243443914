import { gql } from '@apollo/client';
import React, { useState, useCallback, forwardRef, Ref } from 'react';
import classNames from 'classnames';
import { composeImageSrc } from '@moda/portal-stanchions';
import { Image } from '../Image';
import { PumoModuleImageFragment } from '../../generated/types';
import { CONFIG } from '../../config';

import './PumoModuleImage.scss';

export const PUMO_MODULE_IMAGE_FRAGMENT = gql`
  fragment PumoModuleImageFragment on Image {
    imageSrc: image
    altText
    paddingBottom: imagePadding
  }
`;

export const DEFAULT_ALT_TEXT = 'Editorial Image';

export interface Props extends React.HTMLAttributes<HTMLImageElement> {
  pumoModuleImage: PumoModuleImageFragment;
  cover?: boolean;
}

enum Mode {
  Pending,
  Error,
  Loaded
}

export const PumoModuleImage = forwardRef(
  (
    { pumoModuleImage: { imageSrc, altText, paddingBottom }, cover, className }: Props,
    ref: Ref<HTMLDivElement>
  ) => {
    const [mode, setMode] = useState(Mode.Pending);

    const handleLoad = useCallback(() => setMode(Mode.Loaded), []);
    const handleError = useCallback(() => setMode(Mode.Error), []);

    const src = composeImageSrc(imageSrc, { origin: CONFIG.MODA_IMG_ROOT });

    return cover ? (
      <div
        className={classNames('PumoModuleImage PumoModuleImage--cover', className)}
        style={{ backgroundImage: `url("${src}")` }}
        role="img"
        aria-label={altText || DEFAULT_ALT_TEXT}
      />
    ) : (
      // paddingBottom is frequently `0` (notably: lower environments)
      // So this component must accomodate both possibilities
      <div
        className={classNames('PumoModuleImage', className, {
          'PumoModuleImage--responsive': paddingBottom
        })}
        ref={ref}
        style={{ paddingBottom: paddingBottom && `${paddingBottom}%` }}
      >
        {mode !== Mode.Error && (
          <Image
            className="PumoModuleImage__image"
            src={imageSrc}
            alt={altText || DEFAULT_ALT_TEXT}
            onError={handleError}
            onLoad={handleLoad}
            width="360"
            height="640"
          />
        )}
      </div>
    );
  }
);
