import React, { useCallback, useEffect, useMemo } from 'react';
import { gql } from '@apollo/client';
import { AspectRatioBox, Clickable, useBreakpoint } from '@moda/om';
import SoundOffIcon from '@moda/icons/sound-off-36';
import SoundOnIcon from '@moda/icons/sound-on-36';
import PlayIconFilled from '@moda/icons/play-filled-72';
import PauseIconFilled from '@moda/icons/pause-filled-72';
import { uniq } from 'ramda';
import { EditorialVideoFragment } from '../../generated/types';
import { VideoPlayer, useVideoPlayer } from '../VideoPlayer';
import { Loading } from '../Loading';
import { useVideoIconSizeProps } from '../../hooks/useVideoIconSizeProps';

import './EditorialVideo.scss';

const MOBILE_SIZE_PROP = { lt: 'sm' };

export const EDITORIAL_VIDEO_FRAGMENT = gql`
  fragment EditorialVideoFragment on EditorialVideo {
    id
    placement
    width
    height
    hasAudio: has_audio
    mobileId: mobile_id
    mobileWidth: mobile_width
    mobileHeight: mobile_height
    mobileHasAudio: mobile_has_audio
  }
`;

interface Props {
  video: EditorialVideoFragment;
}

const getWatchedVideoIds = () => {
  try {
    const watchedVideoIds: unknown = JSON.parse(localStorage.getItem('watchedVideoIds') || '[]');
    return Array.isArray(watchedVideoIds) ? watchedVideoIds.map(String) : [];
  } catch {
    return [];
  }
};

const hasWatchedVideo = (videoId: string) => getWatchedVideoIds().includes(videoId);

const markVideoAsWatched = (videoId: string) => {
  localStorage.setItem('watchedVideoIds', JSON.stringify(uniq([...getWatchedVideoIds(), videoId])));
};

export const EditorialVideo: React.FC<Props> = ({
  video: {
    height: desktopHeight,
    width: desktopWidth,
    id: desktopId,
    hasAudio: desktopHasAudio,
    mobileHeight,
    mobileWidth,
    mobileId,
    mobileHasAudio
  }
}) => {
  const { matches: isMobile } = useBreakpoint(MOBILE_SIZE_PROP);

  const { height, width, hasAudio, id } = useMemo(() => {
    if (isMobile) {
      return {
        width: mobileWidth,
        height: mobileHeight,
        id: mobileId,
        hasAudio: mobileHasAudio
      };
    }

    return {
      width: desktopWidth,
      height: desktopHeight,
      id: desktopId,
      hasAudio: desktopHasAudio
    };
  }, [
    desktopHasAudio,
    desktopHeight,
    desktopId,
    desktopWidth,
    isMobile,
    mobileHasAudio,
    mobileHeight,
    mobileId,
    mobileWidth
  ]);

  const {
    initialize: onLoad,
    loading,
    player,
    isPlaying,
    muted,
    handleMute,
    setHovered,
    showControls
  } = useVideoPlayer({ hasAudio: hasAudio ?? false, isMobile });

  const iconSizeProps = useVideoIconSizeProps(MOBILE_SIZE_PROP);

  const handleOver = useCallback(() => {
    if (isMobile) return;

    setHovered(true);
  }, [isMobile, setHovered]);

  const handleLeave = useCallback(() => {
    if (isMobile) return;

    setHovered(false);
  }, [isMobile, setHovered]);

  const handlePlayPause = useCallback(() => {
    if (!player) return;

    if (isPlaying) {
      player.pause();
    }

    if (!isPlaying) {
      player.play();
    }
  }, [isPlaying, player]);

  useEffect(() => {
    player?.on('pause', () => {
      if (id && player.remainingTime() === 0) markVideoAsWatched(id);
    });
  }, [player, id]);

  if (!id) return null;

  if (!height) return null;

  if (!width) return null;

  return (
    <AspectRatioBox
      className="EditorialVideo"
      onFocus={handleOver}
      onMouseLeave={handleLeave}
      onMouseOver={handleOver}
      role="button"
      tabIndex={0}
      aspectWidth={width}
      aspectHeight={height}
    >
      {loading && (
        <div className="EditorialVideo__loading">
          <Loading />
        </div>
      )}

      {showControls && (
        <>
          <Clickable
            aria-label={isPlaying ? 'pause' : 'play'}
            className="EditorialVideo__button EditorialVideo__play-pause-button"
            onClick={handlePlayPause}
          >
            <PauseIconFilled
              className={!isPlaying ? 'EditorialVideo__item--hidden' : undefined}
              {...iconSizeProps}
            />

            <PlayIconFilled
              className={isPlaying ? 'EditorialVideo__item--hidden' : undefined}
              {...iconSizeProps}
            />
          </Clickable>

          {hasAudio && (
            <>
              <Clickable
                aria-label={muted ? 'unmute' : 'mute'}
                className="EditorialVideo__button EditorialVideo__sound-button"
                onClick={handleMute}
              >
                <SoundOffIcon
                  className={!muted ? 'EditorialVideo__item--hidden' : undefined}
                  {...iconSizeProps}
                />

                <SoundOnIcon
                  className={muted ? 'EditorialVideo__item--hidden' : undefined}
                  {...iconSizeProps}
                />
              </Clickable>
            </>
          )}
        </>
      )}

      <VideoPlayer
        playerProps={{
          embedOptions: { responsive: { aspectRatio: `${width}:${height}` } },
          options: { autoplay: !hasWatchedVideo(id) },
          videoId: id
        }}
        key={id}
        onLoad={onLoad}
      />
    </AspectRatioBox>
  );
};
