import React, { useMemo } from 'react';
import { gql } from '@apollo/client';
import classNames from 'classnames';
import { sum, flatten } from 'ramda';
import { fromUnixTime, differenceInDays } from 'date-fns';
import { Stack, Text } from '@moda/om';
import { Availability, FulfillmentTypes, formatPercentage } from '@moda/portal-stanchions';
import { AdminModeProductInfoFragment } from '../../../generated/types';
import {
  ADMIN_MODE_PRODUCT_INFO_COLOR_FRAGMENT,
  useAdminModeProductInfoColor
} from './useAdminModeProductInfoColor';

import './AdminModeProductInfo.scss';

export const ADMIN_MODE_PRODUCT_INFO_VARIANT_FRAGMENT = gql`
  fragment AdminModeProductInfoVariantFragment on VariantData {
    id
    inventory {
      available
    }
    primaryImageUrls: primary_image_urls {
      large
    }
    alternateImageUrls: alternate_image_urls {
      large
    }
  }
`;

export const ADMIN_MODE_PRODUCT_INFO_FRAGMENT = gql`
  fragment AdminModeProductInfoFragment on Variant {
    id
    name
    availability
    fulfilmentType: fulfilment_type
    sellThroughAllTime: calculated_sell_through_all_time
    startDate: active_start_i
    score
    masterVariant: master_variants_data {
      ...AdminModeProductInfoVariantFragment
    }
    otherVariants: other_variants_data {
      ...AdminModeProductInfoVariantFragment
    }
    ...AdminModeProductInfoColorFragment
  }
  ${ADMIN_MODE_PRODUCT_INFO_VARIANT_FRAGMENT}
  ${ADMIN_MODE_PRODUCT_INFO_COLOR_FRAGMENT}
`;

type Props = {
  className?: string;
  variant: AdminModeProductInfoFragment;
  activeVariantId: string;
};

export const AdminModeProductInfo: React.FC<Props> = ({ className, variant, activeVariantId }) => {
  const { availability, sellThroughAllTime, startDate, masterVariant, otherVariants, score } =
    variant;
  const isTrunkshow = availability === String(Availability.Trunkshow);
  const daysOnSite = startDate ? differenceInDays(new Date(), fromUnixTime(startDate)) : 'N/A';
  const hasMultipleVariants = [masterVariant, ...otherVariants].length > 1;
  const activeVariant = [masterVariant, ...otherVariants].find(
    variant => variant && variant.id === activeVariantId
  );
  const activeVariantQuantityRemaining = sum(
    (activeVariant?.inventory || []).map(sku => sku?.available || 0)
  );
  const productQuantityRemaining = sum(
    flatten(
      [masterVariant, ...otherVariants].map(variant =>
        (variant?.inventory || []).map(sku => sku?.available || 0)
      )
    )
  );

  const masterVariantImageSrcs = flatten(
    [masterVariant.primaryImageUrls, ...masterVariant.alternateImageUrls].map(url => url?.large)
  );

  const otherVariantImageSrcs = flatten(
    otherVariants.map(variant => [
      [variant?.primaryImageUrls].map(url => url?.large),
      variant?.alternateImageUrls.map(url => url?.large)
    ])
  );

  const bgColor = useAdminModeProductInfoColor(variant);

  const imageSrcs = useMemo(
    () => [...masterVariantImageSrcs, ...otherVariantImageSrcs],
    [masterVariantImageSrcs, otherVariantImageSrcs]
  );

  const isInternalDropship = variant.fulfilmentType === FulfillmentTypes.DropShip;
  const isMiraklDropship = variant.fulfilmentType === FulfillmentTypes.MiraklDropship;

  return (
    <Stack
      className={classNames('AdminModeProductInfo', className)}
      style={{ backgroundColor: bgColor }}
      space={0}
    >
      {isMiraklDropship && (
        <Text treatment="body1" color="code-red">
          Mirakl Dropship
        </Text>
      )}
      {isInternalDropship && (
        <Text treatment="body1" color="code-red">
          Internal Dropship
        </Text>
      )}
      <Stack direction="horizontal" justifyContent="space-between" space={1}>
        <Text treatment="body2">
          {isTrunkshow
            ? `Number sold: ${sellThroughAllTime}`
            : `Sell-through: ${formatPercentage(sellThroughAllTime)}`}
        </Text>
        {score ? <Text treatment="body2">Score: {score}</Text> : null}
      </Stack>
      <Stack direction="horizontal" justifyContent="space-between" space={1}>
        <Text treatment="body2">Days on site: {daysOnSite}</Text>
        <Text treatment="body2">
          Quantity:{' '}
          {isTrunkshow
            ? 'N/A'
            : `${
                hasMultipleVariants ? activeVariantQuantityRemaining + '/' : ''
              }${productQuantityRemaining}`}
        </Text>
      </Stack>
      <span className="AdminModeProductInfo__srcs" data-images-src={imageSrcs} />
    </Stack>
  );
};
