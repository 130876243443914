import { useEffect } from 'react';
import { useList as useReactUseList } from 'react-use';

export const useList: typeof useReactUseList = initialList => {
  const [list, actions] = useReactUseList(initialList);

  useEffect(() => {
    if (initialList) actions.set(initialList);
  }, [initialList, actions]);

  return [list, actions];
};
