import React from 'react';
import { Divider } from '@moda/om';
import classNames from 'classnames';
import { HomePageHeaderListItemFragment } from '../../generated/types';
import { HomePageHeaderListItem } from './HomePageHeaderListItem';
import './HomePageHeaderList.scss';

export interface Props {
  list: HomePageHeaderListItemFragment[];
  headerText?: string;
  className: string;
}

export const HomePageHeaderList: React.FC<Props> = ({ list, headerText, className }) => (
  <div className={classNames('HomePageHeaderList', className)}>
    {Boolean(headerText) && (
      <h2 className="HomePageHeaderList__header">
        <Divider text={headerText} />
      </h2>
    )}

    {list.map(listItem => (
      <HomePageHeaderListItem key={listItem.ctaUrl} listItem={listItem} />
    ))}
  </div>
);
