import { gql } from '@apollo/client';
import React from 'react';
import { useModuleTracking } from '../../hooks/useModuleTracking';
import { EmailPumoModuleFragment } from '../../generated/types';
import { NormalizePumoStyles } from '../NormalizePumoStyles';
import { PumoModuleSlide, PUMO_MODULE_SLIDE_FRAGMENT } from '../PumoModuleSlide';
import { EmailPumoModuleForm, EMAIL_PUMO_MODULE_FORM_FRAGMENT } from './EmailPumoModuleForm';

import './EmailPumoModule.scss';

interface Props {
  emailPumoModule: EmailPumoModuleFragment;
}

export const EMAIL_PUMO_MODULE_FRAGMENT = gql`
  fragment EmailPumoModuleFragment on EmailPumoModule {
    name
    slideUnit {
      ...PumoModuleSlideFragment
    }
    emailUnit {
      ...EmailPumoModuleFormFragment
    }
    moduleType
    modelId
  }
  ${PUMO_MODULE_SLIDE_FRAGMENT}
  ${EMAIL_PUMO_MODULE_FORM_FRAGMENT}
`;

export const EmailPumoModule: React.FC<Props> = ({
  emailPumoModule: { slideUnit, emailUnit, modelId, moduleType, name }
}) => {
  const { ref } = useModuleTracking({ name });

  return (
    <NormalizePumoStyles
      className="EmailPumoModule"
      data-model-id={modelId}
      data-module-type={moduleType}
    >
      <div className="EmailPumoModule__image">
        <PumoModuleSlide className="EmailPumoModule__slide" slide={slideUnit} />
      </div>

      <div className="EmailPumoModule__email-unit" ref={ref}>
        <EmailPumoModuleForm className="EmailPumoModule__form" emailUnitForm={emailUnit} />
      </div>
    </NormalizePumoStyles>
  );
};
