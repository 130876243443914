import { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { hrefFor } from '../../routers';
import { useAttentiveSubscribe } from '../../hooks/useEmailSubscribe/useAttentiveSubscribe';

export const useSmsPumoModule = () => {
  const { pathname } = useLocation();
  const [phone, setPhone] = useState('');
  const {
    subscribe,
    isSubscribed,
    loading,
    error,
    isEmailValid,
    isPhoneNumberValid,
    mailValidationError,
    phoneNumberValidationError
  } = useAttentiveSubscribe();

  const [email, setEmail] = useState('');

  const handleSubmit = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();

      if (!isEmailValid(email) || !isPhoneNumberValid) return;

      subscribe({
        email,
        phone,
        label: 'smsPumoModule',
        source: pathname === hrefFor.BeautyLandingPage() ? 'beauty' : undefined
      });
    },
    [email, isEmailValid, isPhoneNumberValid, phone, subscribe, pathname]
  );

  return {
    phone,
    handleChangePhone: setPhone,
    handleSubmit,
    error,
    mailValidationError,
    phoneNumberValidationError,
    email,
    isSubscribed,
    serverError: error,
    handleChangeEmail: setEmail,
    loading
  };
};
