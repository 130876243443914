import classNames from 'classnames';
import React, { ComponentProps } from 'react';
import { SwiperSlide } from 'swiper/react';
import { space } from '@moda/tokens';
import ChevronLeftIcon from '@moda/icons/chevron-left-60';
import ChevronRightIcon from '@moda/icons/chevron-right-60';
import ChevronLeftCircle40 from '@moda/icons/chevron-left-circle-40';
import ChevronRightCircle40 from '@moda/icons/chevron-right-circle-40';
import { Swiper } from '../Swiper';

import './SwiperCarousel.scss';

const SMALL_SIZE = 6;
const CHEVRON_SIZE = space.scale[SMALL_SIZE];

export type SwiperCarouselProps = ComponentProps<typeof Swiper> & {
  className?: string;
  classNameNavigation?: string;
  classNameWrapper?: string;
  identifier: string;
  classNameSlide?: string;
  circleArrow?: boolean;
};

export const SwiperCarousel: React.FC<SwiperCarouselProps> = ({
  children,
  className,
  classNameNavigation,
  classNameWrapper,
  classNameSlide,
  identifier,
  circleArrow,
  ...rest
}) => {
  if (React.Children.count(children) === 0) return null;

  return (
    <div
      key={React.Children.count(children)}
      className={classNames('SwiperCarousel__wrapper', classNameWrapper)}
      id={identifier}
    >
      <Swiper
        centerInsufficientSlides
        className={classNames('SwiperCarousel', className)}
        containerModifierClass="SwiperCarousel--"
        navigation={{
          prevEl: `#${identifier} .SwiperCarousel__prev`,
          nextEl: `#${identifier} .SwiperCarousel__next`,
          disabledClass: 'SwiperCarousel__navigation--disabled'
        }}
        pagination={{
          modifierClass: 'SwiperCarousel__pagination--',
          type: 'bullets',
          clickable: true,
          hideOnClick: false,
          bulletClass: 'SwiperCarousel__bullet',
          bulletActiveClass: 'SwiperCarousel__bullet--active'
        }}
        speed={500}
        {...rest}
      >
        {React.Children.map(
          children,
          (child, index) =>
            child && (
              <SwiperSlide
                className={classNames('SwiperCarousel__slide', classNameSlide)}
                key={React.isValidElement(child) ? child.key : index}
              >
                {child}
              </SwiperSlide>
            )
        )}
      </Swiper>

      <div className={classNames('SwiperCarousel__prev', classNameNavigation)}>
        {circleArrow ? (
          <ChevronLeftCircle40
            className="ProductSwiperCarousel__chevron"
            width={CHEVRON_SIZE}
            height={CHEVRON_SIZE}
          />
        ) : (
          <ChevronLeftIcon width={CHEVRON_SIZE} height={CHEVRON_SIZE} />
        )}
      </div>

      <div className={classNames('SwiperCarousel__next', classNameNavigation)}>
        {circleArrow ? (
          <ChevronRightCircle40
            className="ProductSwiperCarousel__chevron"
            width={CHEVRON_SIZE}
            height={CHEVRON_SIZE}
          />
        ) : (
          <ChevronRightIcon width={CHEVRON_SIZE} height={CHEVRON_SIZE} />
        )}
      </div>
    </div>
  );
};
