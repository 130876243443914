import { gql, TypedDocumentNode } from '@apollo/client';

export type UserGroupsQuery = {
  userGroups: {
    __typename: 'RestUserGroupsQuery';
    data: {
      __typename: 'RestUserGroups';
      id: string;
      attributes: {
        __typename: 'RestUserGroupsAttributes';
        groupIds: number[];
      };
    };
  };
};

export type UserGroupsQueryVariables = {
  id: string;
};

export const USER_GROUPS_QUERY: TypedDocumentNode<UserGroupsQuery, UserGroupsQueryVariables> = gql`
  query UserGroupsQuery($id: String!) {
    userGroups(id: $id)
      @rest(type: "RestUserGroupsQuery", endpoint: "user", path: "/v1/users/{args.id}") {
      data @type(name: "RestUserGroups") {
        id
        attributes @type(name: "RestUserGroupsAttributes") {
          groupIds: group_ids
        }
      }
    }
  }
`;
