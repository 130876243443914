import { gql } from '@apollo/client';
import React, { useState, useCallback } from 'react';
import { AspectRatioBox, Overlay, ControlLink, Clickable } from '@moda/om';
import { useParams } from 'react-router-dom';
import isTouchDevice from 'is-touch-device';
import classNames from 'classnames';
import loadable from '@loadable/component';
import { Image } from '../../../Image';
import { LookCellFragment } from '../../../../generated/types';
import { hrefFor } from '../../../../routers';
import { useAdminMode } from '../../../AdminMode/AdminModeProvider';
import { Loading } from '../../../Loading';

import './LookCell.scss';

const AdminModeLookOverlay = loadable(() => import('../../../AdminMode/AdminModeLookOverlay'), {
  fallback: <Loading />
});

export const LOOK_CELL_FRAGMENT = gql`
  fragment LookCellFragment on Look {
    id
    name
    trunkshowSlugs: trunkshow_slugs
    trunkshowId: trunkshow_id
    primaryImageUrls: primary_image_urls {
      src: raw
    }
    variantIds: variant_ids
  }
`;

enum Mode {
  Resting,
  Hovered
}

interface Props {
  look: LookCellFragment;
  className?: string;
}

export const LookCell: React.FC<Props> = ({ look, className }) => {
  const [mode, setMode] = useState(Mode.Resting);

  const { vertical = 'women' } = useParams<{ vertical?: string }>();

  const { enabled: adminModeEnabled } = useAdminMode();

  const handleMouseEnter = useCallback(() => {
    if (isTouchDevice()) return;
    setMode(Mode.Hovered);
  }, []);

  const handleMouseLeave = useCallback(() => {
    if (isTouchDevice()) return;
    setMode(Mode.Resting);
  }, []);

  if (!look.primaryImageUrls?.src) return null;

  const href: string | undefined =
    look.trunkshowId && look.trunkshowSlugs && look.trunkshowSlugs.length > 0
      ? hrefFor.LookviewPage({
          vertical: vertical,
          trunkshowSlug: `${look.trunkshowSlugs[0]}`,
          trunkshowId: `${look.trunkshowId}`,
          lookId: look.id
        })
      : undefined;

  return (
    <div
      className={classNames('LookCell', className)}
      data-pk={look.id}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {adminModeEnabled && (
        <AdminModeLookOverlay lookId={look.id} imageSrc={look.primaryImageUrls.src} />
      )}

      <Clickable to={href}>
        <AspectRatioBox maxHeight={540} maxWidth={340}>
          <Image
            className="LookCell__image"
            src={look.primaryImageUrls.src}
            alt={look.name || 'Shop Look'}
          />
        </AspectRatioBox>

        <Overlay show={mode === Mode.Hovered && href !== undefined}>
          <ControlLink underlined={false} to={href}>
            SHOP LOOK
          </ControlLink>
        </Overlay>
      </Clickable>
    </div>
  );
};
