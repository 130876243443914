import React from 'react';
import { gql } from '@apollo/client';
import { Button, Breakpoint } from '@moda/om';
import { CarouselPumoModuleCategoriesFragment } from '../../generated/types';
import { CAROUSEL_DEFAULT_CONFIG, SwiperCarousel } from '../Swiper';
import { CategoryCell, CATEGORY_CELL_FRAGMENT } from './CategoryCell';
import './CarouselPumoModuleCategories.scss';

export const CAROUSEL_PUMO_MODULE_CATEGORIES_FRAGMENT = gql`
  fragment CarouselPumoModuleCategoriesFragment on CarouselPumoModule {
    id
    cells {
      ...CategoryCellFragment
    }
  }
  ${CATEGORY_CELL_FRAGMENT}
`;

type Props = {
  carouselPumoModule: CarouselPumoModuleCategoriesFragment;
  onClick?: (event: Event) => void;
};

export const CarouselPumoModuleCategories: React.FC<Props> = ({ carouselPumoModule, onClick }) => (
  <>
    <Breakpoint gt="md">
      <SwiperCarousel
        {...CAROUSEL_DEFAULT_CONFIG}
        identifier={`CarouselPumoModuleCategories-${carouselPumoModule.id}`}
        onClick={(swiper, event) => onClick?.(event)}
      >
        {carouselPumoModule.cells.map(cell =>
          cell.__typename === 'Slide' ? <CategoryCell key={cell.id} cell={cell} /> : null
        )}
      </SwiperCarousel>
    </Breakpoint>
    <Breakpoint className="CarouselPumoModuleCategories__mobile" lt="md">
      <div className="CarouselPumoModuleCategories__list">
        {carouselPumoModule.cells.map(cell =>
          cell.__typename === 'Slide' ? (
            <Button
              key={cell.id}
              className="CarouselPumoModuleCategories__button"
              tertiary
              elevated
              href={cell.ctaUrl || '/'}
            >
              {cell.slideText?.ctaText}
            </Button>
          ) : null
        )}
      </div>
    </Breakpoint>
  </>
);
