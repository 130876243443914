import { gql, TypedDocumentNode } from '@apollo/client';

export type RecommendationsQuery = {
  recommendations: {
    __typename: 'RestRecommendations';
    data: {
      __typename: 'RestRecommendationsData';
      id: string;
      type: string;
      attributes: {
        __typename: 'RestRecommendationsAttributes';
        variantIds: string[];
      };
    };
  };
};

export type RecommendationsQueryVariables = {
  type: string;
  id: string;
  limit: number;
  filter: string;
};

export const RECOMMENDATIONS_QUERY: TypedDocumentNode<
  RecommendationsQuery,
  RecommendationsQueryVariables
> = gql`
  query RecommendationsQuery($type: String!, $id: String!, $limit: Int!, $filter: String!) {
    recommendations(type: $type, id: $id, limit: $limit, filter: $filter)
      @rest(
        type: "RestRecommendations"
        endpoint: "recommendations"
        path: "/{args.type}?variantId={args.id}&k={args.limit}&filter={args.filter}"
      ) {
      data @type(name: "RestRecommendationsData") {
        id
        type
        attributes @type(name: "RestRecommendationsAttributes") {
          variantIds
        }
      }
    }
  }
`;

export type DesignerAffinitiesQuery = {
  designerAffinities: {
    __typename: 'DesignerAffinities';
    top: number[];
    similar: number[];
  };
};

export type DesignerAffinitiesQueryVariables = {
  userId: string;
  limit: number;
};

export const DESIGNER_AFFINITIES_QUERY: TypedDocumentNode<
  DesignerAffinitiesQuery,
  DesignerAffinitiesQueryVariables
> = gql`
  query DesignerAffinitiesQuery($userId: String!, $limit: Int!) {
    designerAffinities(userId: $userId, limit: $limit)
      @rest(
        type: "DesignerAffinities"
        endpoint: "recommendations"
        path: "/designer_affinities/top_designers/{args.userId}?limit={args.limit}"
      ) {
      top
      similar
    }
  }
`;

export type RecentlyViewedQuery = {
  recentlyViewed: {
    __typename: 'RestRecentlyViewedQuery';
    data: {
      __typename: 'RestRecentlyViewedQueryData';
      attributes: {
        __typename: 'RestRecentlyViewedQueryAttributes';
        variantIds: string[];
      };
    };
  } | null;
};

export type RecentlyViewedQueryVariables = {
  id: string;
};

export const RECENTLY_VIEWED_QUERY: TypedDocumentNode<
  RecentlyViewedQuery,
  RecentlyViewedQueryVariables
> = gql`
  query RecentlyViewedQuery($id: String) {
    recentlyViewed(id: $id)
      @rest(method: "GET", endpoint: "recommendations", path: "/recently-viewed?userId={args.id}") {
      data @type(name: "RestRecentlyViewedQueryData") {
        id
        attributes @type(name: "RestRecentlyViewedQueryAttributes") {
          variantIds: variant_ids
        }
      }
    }
  }
`;
