import { useSkippableQuery } from '../useSkippableQuery';
import { useUser } from '../useUser';
import { USER_GROUPS_QUERY } from './userGroups.rest';

export const useUserGroups = ({ skip = false }: { skip?: boolean } = { skip: false }) => {
  const {
    user: { id }
  } = useUser();
  const { data, loading } = useSkippableQuery(USER_GROUPS_QUERY, {
    variables: { id: id || '' },
    skip: !id || skip
  });

  return { userGroups: data?.userGroups.data.attributes.groupIds || [], loading };
};
