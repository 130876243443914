import React, { useCallback } from 'react';
import classNames from 'classnames';
import { Clickable } from '@moda/om';
import './VariantCellSizeSliderButton.scss';

type Props = {
  size: string;
  selected: boolean;
  disabled: boolean;
  onClick(size: string): void;
  onSelect(size: string | undefined): void;
};

export const VariantCellSizeSliderButton: React.FC<Props> = ({
  selected,
  size,
  disabled,
  onSelect,
  onClick
}) => {
  const handleClick = useCallback(() => onClick(size), [onClick, size]);

  const handleMouseOver = useCallback(() => onSelect(size), [onSelect, size]);
  const handleMouseOut = useCallback(() => onSelect(undefined), [onSelect]);

  return (
    <Clickable
      className={classNames('VariantCellSizeSliderButton', {
        'VariantCellSizeSliderButton--selected': selected
      })}
      title={size}
      disabled={disabled}
      onClick={handleClick}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      onBlur={handleMouseOut}
      onFocus={handleMouseOver}
    >
      {size}
    </Clickable>
  );
};
