import { gql } from '@apollo/client';
import React from 'react';
import { SECONDS_TO_MILLISECONDS } from '@moda/portal-stanchions';
import { TrunkshowCountdownPumoModuleFragment } from '../../generated/types';
import { NormalizePumoStyles } from '../NormalizePumoStyles';
import { PumoModuleSlide, PUMO_MODULE_SLIDE_FRAGMENT } from '../PumoModuleSlide';
import { EDITORIAL_BG_COLORS, EditorialBgColor } from '../PumoModuleEditorialText';
import { ModuleTrunkshowCountdown } from './ModuleTrunkshowCountdown';

import './TrunkshowCountdownPumoModule.scss';

export interface Props {
  trunkshowCountdownPumoModule: TrunkshowCountdownPumoModuleFragment;
}

export const TRUNKSHOW_COUNTDOWN_PUMO_MODULE_FRAGMENT = gql`
  fragment TrunkshowCountdownPumoModuleFragment on TrunkshowCountdownPumoModule {
    name
    bgColor
    slide {
      ...PumoModuleSlideFragment
    }
    trunkshow {
      startDate: active_start_i
      endDate: active_end_i
      slug
      name
    }
    modelId
    moduleType
  }
  ${PUMO_MODULE_SLIDE_FRAGMENT}
`;

export const TrunkshowCountdownPumoModule: React.FC<Props> = ({
  trunkshowCountdownPumoModule: { bgColor, slide, trunkshow, modelId, moduleType }
}) => {
  return (
    <NormalizePumoStyles
      style={{
        backgroundColor: EDITORIAL_BG_COLORS[bgColor as EditorialBgColor]
      }}
      data-model-id={modelId}
      data-module-type={moduleType}
    >
      <PumoModuleSlide slide={slide}>
        <ModuleTrunkshowCountdown
          className="TrunkshowCountdownPumoModule__countdown"
          endDate={(trunkshow?.endDate || 0) * SECONDS_TO_MILLISECONDS}
          startDate={(trunkshow?.startDate || 0) * SECONDS_TO_MILLISECONDS}
          slug={trunkshow?.slug || ''}
          name={trunkshow?.name || ''}
        />
      </PumoModuleSlide>
    </NormalizePumoStyles>
  );
};
