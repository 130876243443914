import { gql } from '@apollo/client';
import React, { useMemo } from 'react';
import { sortBy } from 'ramda';
import classNames from 'classnames';
import { Clickable, ColorSwatch, Text } from '@moda/om';
import isTouchDevice from 'is-touch-device';
import { ColorSelectorFragment } from '../../generated/types';
import { isDiscounted } from '../../lib/variantUtils/isDiscounted';

import './ColorSelector.scss';

export const COLOR_SELECTOR_VARIANT_FRAGMENT = gql`
  fragment ColorSelectorVariantFragment on VariantData {
    id
    color
    customColorName: custom_color_name
    customColorValue: custom_color_value
    colorSwatchImageUrl: color_swatch_image_url
    soldOut: sold_out
    prices(country_code: $countryCode) {
      originalPrice: original_price {
        price
      }
      currentPrice: current_price {
        price
      }
    }
  }
`;

export const COLOR_SELECTOR_FRAGMENT = gql`
  fragment ColorSelectorFragment on Variant {
    variantIds: variant_ids
    masterVariant: master_variants_data {
      ...ColorSelectorVariantFragment
    }
    otherVariants: other_variants_data {
      ...ColorSelectorVariantFragment
    }
  }
  ${COLOR_SELECTOR_VARIANT_FRAGMENT}
`;

interface Props {
  size?: 'default' | 'small';
  layout?: 'grid' | 'flex';
  maxVisibleColors?: number;
  variant: ColorSelectorFragment;
  onSelect(color: string): void;
  onMouseEnter?(color: string): void;
  onMouseLeave?(): void;
  selected?: string | null;
  className?: string;
  href?: string;
}

export const ColorSelector: React.FC<Props> = ({
  size,
  layout = 'flex',
  maxVisibleColors = Infinity,
  variant,
  onSelect,
  onMouseEnter,
  onMouseLeave,
  selected,
  className,
  href
}) => {
  const { variantIds } = variant;
  const colors = useMemo(
    () =>
      sortBy(
        variant => variantIds.indexOf(Number(variant.id)),
        [variant.masterVariant, ...variant.otherVariants]
      ),
    [variant.masterVariant, variant.otherVariants, variantIds]
  );

  const numOfVisibleColors = Math.min(colors.length, maxVisibleColors);
  const numOfHiddenColors = colors.length - numOfVisibleColors;

  const visibleColors = colors.slice(0, numOfVisibleColors);

  return (
    <div className={classNames('ColorSelector', `ColorSelector--${layout}`, className)}>
      {visibleColors.map(variation => {
        const colorName = variation?.customColorName || variation?.color;
        const colorValue = variation?.customColorValue || variation?.color;
        const colorSwatchImageUrl = variation?.colorSwatchImageUrl;

        if (!colorName) return null;

        return (
          <ColorSwatch
            size={size}
            className="ColorSelector__swatch"
            onClick={(_color, title) => title && onSelect?.(title)}
            onMouseEnter={
              isTouchDevice() ? undefined : (_color, title) => title && onMouseEnter?.(title)
            }
            onMouseLeave={isTouchDevice() ? undefined : onMouseLeave}
            key={variation.id}
            color={colorValue || '#fff'}
            title={colorName}
            backgroundUrl={colorSwatchImageUrl || undefined}
            soldOut={variation.soldOut}
            selected={colorName === selected}
            onSale={isDiscounted(variation.prices)}
          />
        );
      })}
      {numOfHiddenColors > 0 && (
        <Clickable className="ColorSelector__more" href={href}>
          <Text treatment="body1">+{numOfHiddenColors}</Text>
        </Clickable>
      )}
    </div>
  );
};
