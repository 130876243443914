import { prop } from 'ramda';
import SearchInsights from 'search-insights';
import { AddToBagFragment, AddToBagInventoryFragment } from '../../generated/types';
import { getActiveVariantBy } from '../../lib/getActiveVariantBy';
import { tc } from '../../lib/trackingContext';
import { getVariantClickHistory } from '../../lib/variantClicksHistory';
import { getCookies } from '../useCookies';
import { getUserFromCookies } from '../useUser';
import { AddToBagMutation } from './addToBag.rest';

type TrackAddToBagArgs = {
  activeVariantId: string;
  data: AddToBagMutation;
  size: AddToBagInventoryFragment | null;
  variant: AddToBagFragment;
};

export const trackAddToBag = ({ activeVariantId, data, size, variant }: TrackAddToBagArgs) => {
  const { modaAnonymousId } = getCookies();
  const user = getUserFromCookies();
  const activeVariant = getActiveVariantBy(variant, 'id', activeVariantId);

  SearchInsights('convertedObjectIDsAfterSearch', {
    userToken: user.id ?? modaAnonymousId ?? undefined,
    index: variant.index,
    eventName: 'Product Added',
    objectIDs: [variant.objectid],
    queryID: getVariantClickHistory(variant.id)?.queryId ?? variant.queryid
  });

  tc.track('Product Added', {
    addToScopeOnce: {
      variant,
      activeVariant,
      size,
      products: data?.addToBag?.data?.[0]?.attributes?.cartItems?.map(prop('data'))
    },
    addToTrackingEvent: {
      label: activeVariantId,
      category: [variant.category[0]?.toString(), variant.subcategory[0]?.toString()].join('/'),
      index: variant.index,
      eventType: 'conversion',
      queryID: getVariantClickHistory(variant.id)?.queryId ?? variant.queryid,
      objectID: variant.objectid,
      position: getVariantClickHistory(variant.id)?.position ?? null
    }
  });
};
