import { gql } from '@apollo/client';
import React, { useMemo } from 'react';
import { HomePageHeaderPumoModuleFragment } from '../../generated/types';
import { PumoModuleSlide, PUMO_MODULE_SLIDE_FRAGMENT } from '../PumoModuleSlide';
import { NormalizePumoStyles } from '../NormalizePumoStyles';
import { Carousel } from '../Carousel';
import { RestrictPlatform } from '../RestrictPlatform';
import { useModuleTracking } from '../../hooks/useModuleTracking';
import { HomePageHeaderList } from './HomePageHeaderList';
import { HOMEPAGE_HEADER_LIST_ITEM_FRAGMENT } from './HomePageHeaderListItem';

import './HomePageHeaderPumoModule.scss';

interface Props {
  homePageHeaderPumoModule: HomePageHeaderPumoModuleFragment;
}

export const HOMEPAGE_HEADER_PUMO_MODULE_FRAGMENT = gql`
  fragment HomePageHeaderPumoModuleFragment on HomePageHeaderPumoModule {
    name
    headerText
    platforms: platform
    list {
      ...HomePageHeaderListItemFragment
    }
    slides {
      featureImage {
        __typename
      }
      mobileImage {
        __typename
      }
      ...PumoModuleSlideFragment
    }
    moduleType
    modelId
  }
  ${HOMEPAGE_HEADER_LIST_ITEM_FRAGMENT}
  ${PUMO_MODULE_SLIDE_FRAGMENT}
`;

export const HomePageHeaderPumoModule: React.FC<Props> = ({
  homePageHeaderPumoModule: { slides, list, headerText, platforms, modelId, moduleType, name }
}) => {
  const { ref } = useModuleTracking({ name });

  const hasValidSlides = useMemo(
    () =>
      slides.some(({ featureImage, mobileImage }) => Boolean(featureImage) || Boolean(mobileImage)),
    [slides]
  );

  return (
    <RestrictPlatform platforms={platforms}>
      <div ref={ref} className="HomePageHeaderPumoModule">
        <NormalizePumoStyles
          className="HomePageHeaderPumoModule"
          data-model-id={modelId}
          data-module-type={moduleType}
        >
          {hasValidSlides && (
            <div className="HomePageHeaderPumoModule__carousel">
              <Carousel>
                {slides.map((slide, index) => (
                  <PumoModuleSlide key={index} slide={slide} index={index + 1} />
                ))}
              </Carousel>
            </div>
          )}

          <HomePageHeaderList
            className="HomePageHeaderPumoModule__list"
            headerText={headerText ?? undefined}
            list={list}
          />
        </NormalizePumoStyles>
      </div>
    </RestrictPlatform>
  );
};
