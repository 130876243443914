import { PumoModuleEditorialTextFragment } from '../../generated/types';

export const doesSlideHaveEditorialText = (
  slideText: PumoModuleEditorialTextFragment | null
): boolean => {
  return (
    slideText != null &&
    (Boolean(slideText.ctaText) ||
      Boolean(slideText.headlineText?.body) ||
      Boolean(slideText.taglineText?.body) ||
      Boolean(slideText.eyebrowText?.body))
  );
};
