import React from 'react';
import { gql } from '@apollo/client';
import loadable from '@loadable/component';
import { VARIANT_CELL_FRAGMENT } from '../VariantCell';
import { LOOK_CELL_FRAGMENT } from '../LookCell';
import { TRUNKSHOW_CELL_FRAGMENT } from '../TrunkshowCell';
import { EDITORIAL_CELL_FRAGMENT } from '../EditorialCell';
import { PlaceholderCell } from '../PlaceholderCell';
import { CarouselCellFragment } from '../../../../generated/types';

export const CAROUSEL_CELL_FRAGMENT = gql`
  fragment CarouselCellFragment on CarouselCell {
    __typename
    ... on Variant {
      ...VariantCellFragment
    }
    ... on Look {
      ...LookCellFragment
    }
    ... on Trunkshow {
      ...TrunkshowCellFragment
    }
    ... on Slide {
      ...EditorialCellFragment
    }
  }
  ${VARIANT_CELL_FRAGMENT}
  ${LOOK_CELL_FRAGMENT}
  ${TRUNKSHOW_CELL_FRAGMENT}
  ${EDITORIAL_CELL_FRAGMENT}
`;

const VariantCell = loadable(() => import('../VariantCell'), {
  resolveComponent: exports => exports.VariantCell,
  fallback: <PlaceholderCell />
});

const LookCell = loadable(() => import('../LookCell'), {
  resolveComponent: exports => exports.LookCell,
  fallback: <PlaceholderCell />
});

const TrunkshowCell = loadable(() => import('../TrunkshowCell'), {
  resolveComponent: exports => exports.TrunkshowCell,
  fallback: <PlaceholderCell />
});

const EditorialCell = loadable(() => import('../EditorialCell'), {
  resolveComponent: exports => exports.EditorialCell,
  fallback: <PlaceholderCell />
});

interface Props {
  carouselCell: CarouselCellFragment;
  moduleName?: string | null;
  index?: number;
}

export const CarouselCell: React.FC<Props> = ({ carouselCell, moduleName, index }) => {
  switch (carouselCell.__typename) {
    case 'Variant':
      return <VariantCell variant={carouselCell} moduleName={moduleName} selfPosition={index} />;
    case 'Trunkshow':
      return <TrunkshowCell trunkshow={carouselCell} />;
    case 'Look':
      return <LookCell look={carouselCell} />;
    case 'Slide':
      return <EditorialCell editorial={carouselCell} />;
    default:
      return null;
  }
};
