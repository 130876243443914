import classNames from 'classnames';
import React, { ComponentProps } from 'react';
import { SwiperSlide } from 'swiper/react';
import { space } from '@moda/tokens';
import ChevronLeftCircle40 from '@moda/icons/chevron-left-circle-40';
import ChevronRightCircle40 from '@moda/icons/chevron-right-circle-40';
import { Swiper } from '../Swiper';

import './ProductSwiperCarousel.scss';

const SMALL_SIZE = 6;
const CHEVRON_SIZE = space.scale[SMALL_SIZE];

export type ProductSwiperCarouselProps = ComponentProps<typeof Swiper> & {
  className?: string;
  classNameNavigation?: string;
  classNameWrapper?: string;
  identifier: string;
  classNameSlide?: string;
};

export const ProductSwiperCarousel: React.FC<ProductSwiperCarouselProps> = ({
  children,
  className,
  classNameNavigation,
  classNameWrapper,
  classNameSlide,
  identifier,
  ...rest
}) => {
  if (React.Children.count(children) === 0) return null;
  return (
    <div
      key={React.Children.count(children)}
      className={classNames('ProductSwiperCarousel__wrapper', classNameWrapper)}
      id={identifier}
    >
      <Swiper
        centerInsufficientSlides
        className={classNames('ProductSwiperCarousel', className)}
        containerModifierClass="ProductSwiperCarousel--"
        navigation={{
          lockClass: 'ProductSwiperCarousel__navigation--locked',
          prevEl: `#${identifier} .ProductSwiperCarousel__prev`,
          nextEl: `#${identifier} .ProductSwiperCarousel__next`,
          disabledClass: 'ProductSwiperCarousel__navigation--disabled'
        }}
        freeMode
        mousewheel={{
          releaseOnEdges: false,
          forceToAxis: true,
          invert: true,
          thresholdDelta: 1,
          thresholdTime: 1
        }}
        speed={500}
        watchOverflow
        {...rest}
      >
        {React.Children.map(
          children,
          (child, index) =>
            child && (
              <SwiperSlide
                className={classNames('ProductSwiperCarousel__slide', classNameSlide)}
                key={React.isValidElement(child) ? child.key : index}
              >
                {child}
              </SwiperSlide>
            )
        )}
      </Swiper>

      <div className={classNames('ProductSwiperCarousel__prev', classNameNavigation)}>
        <ChevronLeftCircle40
          className="ProductSwiperCarousel__chevron"
          width={CHEVRON_SIZE}
          height={CHEVRON_SIZE}
        />
      </div>

      <div className={classNames('ProductSwiperCarousel__next', classNameNavigation)}>
        <ChevronRightCircle40
          className="ProductSwiperCarousel__chevron"
          width={CHEVRON_SIZE}
          height={CHEVRON_SIZE}
        />
      </div>
    </div>
  );
};
