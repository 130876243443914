import { Badge } from '@moda/om';
import React, { FC } from 'react';
import { useCollectionBadges } from '../../hooks/useCollectionBadges';

interface Props {
  collections: (string | null)[];
}

export const CollectionBadge: FC<Props> = ({ collections }) => {
  const collection = useCollectionBadges(collections);

  if (!collection) return null;

  return (
    <Badge className="CollectionBadge" theme="dark-fuchsia">
      {collection.message}
    </Badge>
  );
};
