import brightcovePlayerLoader, {
  BrightcovePlayer,
  BrightcovePlayerLoaderResponse,
  BrightcoveVideoPlayerConfigurableProps
} from '@brightcove/player-loader';
import { useCallback, useState } from 'react';
import { CONFIG } from '../../config';

interface Props {
  target: null | HTMLDivElement;
  playerProps: BrightcoveVideoPlayerConfigurableProps;
  onLoad?: (player: BrightcovePlayer) => void;
}

export const useVideoPlayerLoader = ({ target, playerProps, onLoad }: Props) => {
  const [player, setPlayer] = useState<null | BrightcovePlayer>(null);
  const [loading, setLoading] = useState(false);

  const loadPlayer = useCallback(() => {
    if (!target) return;

    setLoading(true);

    brightcovePlayerLoader({
      accountId: CONFIG.BRIGHTCOVE_ACCOUNT_ID,
      refNode: target,
      ...playerProps
    }).then((response: BrightcovePlayerLoaderResponse) => {
      setLoading(false);
      setPlayer(response.ref);
      onLoad?.(response.ref);
    });
  }, [onLoad, playerProps, target]);

  return { loading, loadPlayer, player };
};
