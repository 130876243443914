import {
  BrightcovePlayer,
  BrightcoveVideoPlayerConfigurableProps
} from '@brightcove/player-loader';
import React, { useCallback, useEffect, useRef } from 'react';
import { useRendered } from './useRendered';
import { useVideoPlayerLoader } from './useVideoPlayerLoader';

import './VideoPlayer.scss';

type Props = {
  playerProps: BrightcoveVideoPlayerConfigurableProps;
  loop?: boolean;
  onLoad?: (player: BrightcovePlayer) => void;
};

export const VideoPlayer = ({ playerProps, loop, onLoad }: Props) => {
  const { rendered: isRendered } = useRendered();
  const ref = useRef<null | HTMLDivElement>(null);

  const handleLoad = useCallback(
    (brightcovePlayer: BrightcovePlayer) => {
      brightcovePlayer.loadingSpinner.dispose();
      if (loop) brightcovePlayer.loop(true);
      brightcovePlayer.controls(false);

      if (!onLoad) return;

      onLoad(brightcovePlayer);
    },
    [onLoad, loop]
  );

  const { loading, loadPlayer, player } = useVideoPlayerLoader({
    target: ref.current,
    playerProps,
    onLoad: handleLoad
  });

  useEffect(() => {
    if (player) return;

    if (!isRendered) return;

    if (!ref.current) return;

    if (loading) return;

    loadPlayer();
  }, [isRendered, loadPlayer, loading, onLoad, player, playerProps]);

  useEffect(() => {
    return () => {
      player?.dispose();
    };
  }, [player]);

  return (
    <div className="VideoPlayer">
      <div ref={ref} />
    </div>
  );
};
