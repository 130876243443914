import React from 'react';
import { splitEvery } from 'ramda';
import classNames from 'classnames';
import { Clickable } from '@moda/om';
import ChevronRightIcon from '@moda/icons/chevron-right-16';
import ChevronLeftIcon from '@moda/icons/chevron-left-16';
import { useCursor } from 'use-cursor';
import { ONE_HUNDRED_PERCENT } from '../../constants';

import './PaginatedSlider.scss';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  per?: number;
};

export const PaginatedSlider: React.FC<Props> = ({ children, className, per = 2, ...rest }) => {
  const pages = splitEvery(per, React.Children.toArray(children));
  const { index, handleNext, handlePrev } = useCursor({ max: pages.length });

  return (
    <div className={classNames('PaginatedSlider', className)} {...rest}>
      <Clickable className="PaginatedSlider__prev" onClick={handlePrev} disabled={index === 0}>
        <ChevronLeftIcon />
      </Clickable>

      <div className="PaginatedSlider__pages">
        <div
          className="PaginatedSlider__rail"
          style={{ transform: `translateX(-${index * ONE_HUNDRED_PERCENT}%)` }}
        >
          {pages.map((page, pageIndex) => (
            <div key={pageIndex} className="PaginatedSlider__page">
              {page.map((child, childIndex) => (
                <div key={childIndex} className="PaginatedSlider__cell">
                  {child}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>

      <Clickable
        className="PaginatedSlider__next"
        onClick={handleNext}
        disabled={index === pages.length - 1}
      >
        <ChevronRightIcon />
      </Clickable>
    </div>
  );
};
