import React from 'react';
import { NormalizePumoStyles } from '../NormalizePumoStyles';
import './PumoModuleEditorialTextElement.scss';

type Kind = 'headline' | 'tagline' | 'eyebrow';

interface Props {
  children: string | null;
  kind: Kind;
  normalizePumoStyles?: boolean;
}

export const PumoModuleEditorialTextElement: React.FC<Props> = ({
  children,
  normalizePumoStyles = true,
  kind
}) => {
  if (children && children !== '') {
    const props =
      kind === 'tagline' ? { dangerouslySetInnerHTML: { __html: children } } : { children };

    return normalizePumoStyles ? (
      <NormalizePumoStyles
        className={`PumoModuleEditorialTextElement PumoModuleEditorialTextElement--${kind}`}
        {...props}
      />
    ) : (
      <div
        className={`PumoModuleEditorialTextElement PumoModuleEditorialTextElement--${kind}`}
        {...props}
      />
    );
  }

  return null;
};
