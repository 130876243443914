import { gql } from '@apollo/client';
import { HasAccessToProductFragment, HasAccessToTrunkshowFragment } from '../generated/types';
import {
  isProductOrTrunkshowRestricted,
  hasAccessToProductOrTrunkshow
} from '../lib/hasAccessToProductOrTrunkshow';
import { useUserGroups } from './useUserGroups';

export const HAS_ACCESS_TO_PRODUCT_FRAGMENT = gql`
  fragment HasAccessToProductFragment on Variant {
    restricted
    privateUntil: trunkshow_private_until
    activeForGroups: active_for_groups
  }
`;

export const HAS_ACCESS_TO_TRUNKSHOW_FRAGMENT = gql`
  fragment HasAccessToTrunkshowFragment on Trunkshow {
    restricted
    privateUntil: private_until_i
    activeForGroups: active_for_groups
  }
`;

const NO_RESTRICTION_RULES = {
  restricted: false,
  privateUntil: null,
  activeForGroups: [] as number[]
};

export const useHasAccessToProductOrTrunkshow = (
  rules?: HasAccessToProductFragment | HasAccessToTrunkshowFragment | null
) => {
  const { restricted, privateUntil, activeForGroups } = rules || NO_RESTRICTION_RULES;

  const { userGroups, loading } = useUserGroups({
    skip: !isProductOrTrunkshowRestricted({ restricted, privateUntil })
  });

  return {
    hasAccess: hasAccessToProductOrTrunkshow({
      restricted,
      privateUntil,
      activeForGroups,
      userGroups
    }),
    loading
  };
};
