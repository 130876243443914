import React, { useCallback } from 'react';
import classNames from 'classnames';
import { Link } from '../Link';
import { PumoModuleSlideFragment } from '../../generated/types';
import { tc } from '../../lib/trackingContext';
import { PumoModuleSlideContent } from './PumoModuleSlideContent';
import { doesSlideHaveEditorialText } from './doesSlideHaveEditorialText';

import './PumoModuleSlide.scss';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  slide: PumoModuleSlideFragment;
  cover?: boolean;
  beforeEyebrow?: React.ReactNode;
  afterEyebrow?: React.ReactNode;
  normalizePumoStyles?: boolean;
  spaceBetween?: boolean;
  index?: number;
  hideCtaUnderline?: boolean;
}

export const PumoModuleSlide: React.FC<Props> = props => {
  const { slide, className, index } = props;
  const handleTrackNavColumnClicked = useCallback(
    (
      url: string | null,
      imageUrl: string | null,
      coordinateX: number,
      coordinateY: number,
      page: string | null,
      position: number
    ) => {
      tc.track('Carousel Module Clicked', {
        addToTrackingEvent: {
          url,
          imageUrl,
          coordinateX,
          coordinateY,
          page,
          position
        }
      });
    },
    []
  );

  if (!slide) return null;

  return (
    <div className={classNames('PumoModuleSlide', className)}>
      {slide.ctaUrl ? (
        <Link
          aria-label={
            doesSlideHaveEditorialText(slide.slideText) ? undefined : `Go to ${slide.ctaUrl}`
          }
          to={slide.ctaUrl}
          onClick={event =>
            handleTrackNavColumnClicked(
              slide.ctaUrl,
              slide.featureImage?.imageSrc || '',
              event.pageX,
              event.pageY,
              window.location.pathname,
              index || 0
            )
          }
        >
          <PumoModuleSlideContent {...props} />
        </Link>
      ) : (
        <PumoModuleSlideContent {...props} />
      )}
    </div>
  );
};
