import React, { useRef, useState, useCallback } from 'react';
import { ONE_HUNDRED_PERCENT } from '../../constants';
import { CarouselArrow } from './CarouselArrow';
import { CarouselDots } from './CarouselDots';
import './Carousel.scss';

interface Props {
  dots?: boolean;
  ignoreMaxWidth?: boolean;
  children: React.ReactNode[];
}

export const Carousel: React.FC<Props> = ({ children, dots = true, ignoreMaxWidth = false }) => {
  const tracksRef = useRef<HTMLDivElement>(null);
  const numOfItems = children.length;

  const [currentCell, setCurrentCell] = useState(0);

  const handlePrev = useCallback(() => {
    setCurrentCell(currentCell - 1 >= 0 ? currentCell - 1 : 0);
  }, [currentCell]);

  const handleNext = useCallback(() => {
    setCurrentCell(currentCell + 1 <= numOfItems - 1 ? currentCell + 1 : numOfItems - 1);
  }, [currentCell, numOfItems]);

  return (
    <div className="Carousel">
      {numOfItems > 1 && (
        <CarouselArrow disabled={currentCell === 0} handleClick={handlePrev} direction="left" />
      )}

      <div className="Carousel__viewer">
        <div
          ref={tracksRef}
          className="Carousel__rail"
          style={{
            width: ignoreMaxWidth ? `100%` : `${ONE_HUNDRED_PERCENT * numOfItems}%`,
            transform: `translateX(-${(currentCell * ONE_HUNDRED_PERCENT) / numOfItems}%)`
          }}
        >
          {React.Children.map(children, (child: React.ReactNode, index: number) => (
            <div key={index} className="Carousel__cell">
              {child}
            </div>
          ))}
        </div>
      </div>

      {numOfItems > 1 && (
        <CarouselArrow
          disabled={currentCell >= numOfItems - 1}
          handleClick={handleNext}
          direction="right"
        />
      )}

      {dots && numOfItems > 1 && (
        <CarouselDots
          className="Carousel__dots"
          length={numOfItems}
          onClick={setCurrentCell}
          cursor={currentCell}
        />
      )}
    </div>
  );
};
