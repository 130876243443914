import { useMemo } from 'react';
import { useFeatureFlag, FeatureFlagVariationValue } from '@moda/portal-stanchions';

type CollectionBadges = {
  [key: string]: {
    collection: string;
    message: string;
  };
};

const isTreatmentValid = (treatment: FeatureFlagVariationValue): treatment is CollectionBadges =>
  treatment != null &&
  typeof treatment === 'object' &&
  !Array.isArray(treatment) &&
  Object.values(treatment).every(
    value =>
      value != null &&
      typeof value === 'object' &&
      !Array.isArray(value) &&
      typeof value.collection === 'string' &&
      typeof value.message === 'string'
  );

export const useCollectionBadges = (collections: (string | null)[]) => {
  const badges = useFeatureFlag('collection-badges', [
    { default: {} },
    { getFromTreatment: treatment => (isTreatmentValid(treatment) ? treatment : {}) }
  ]);

  const collectionsWithLowerCase = useMemo(
    () => collections.map(collection => collection?.toLowerCase()),
    [collections]
  );

  return Object.values(badges).find(badge =>
    collectionsWithLowerCase.includes(badge.collection.toLowerCase())
  );
};
