import { isAfter } from 'date-fns';
import { intersection } from 'ramda';

export const isProductOrTrunkshowRestricted = ({
  restricted,
  privateUntil
}: {
  restricted?: boolean;
  privateUntil?: number | null;
}) => restricted || isAfter(privateUntil ?? 0, new Date());

export const hasAccessToProductOrTrunkshow = ({
  restricted,
  privateUntil,
  activeForGroups,
  userGroups
}: {
  restricted: boolean;
  privateUntil: number | null;
  activeForGroups: number[];
  userGroups: number[];
}) => {
  // if the products/trunkshow is not restricted or private, everyone has access
  if (!isProductOrTrunkshowRestricted({ restricted, privateUntil })) return true;

  // otherwise check whether the user is in a group that has access
  return intersection(activeForGroups, userGroups).length > 0;
};
