import { gql } from '@apollo/client';
import React from 'react';
import { PumoModuleSlide, PUMO_MODULE_SLIDE_FRAGMENT } from '../../../PumoModuleSlide';
import { EditorialCellFragment } from '../../../../generated/types';

interface Props {
  editorial: EditorialCellFragment;
}

export const EDITORIAL_CELL_FRAGMENT = gql`
  fragment EditorialCellFragment on Slide {
    ...PumoModuleSlideFragment
  }
  ${PUMO_MODULE_SLIDE_FRAGMENT}
`;

export const EditorialCell: React.FC<Props> = ({ editorial }: Props) => (
  <PumoModuleSlide slide={editorial} />
);
