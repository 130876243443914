type VariantClickHistory = {
  queryId: string;
  position?: number;
};

type VariantClicksHistory = {
  [variantId: string]: VariantClickHistory;
};

const getVariantClicksHistory = (): VariantClicksHistory => {
  try {
    return JSON.parse(localStorage.getItem('variantClicksHistory') ?? '{}');
  } catch {
    return {};
  }
};

export const getVariantClickHistory = (variantId: string): VariantClickHistory | undefined =>
  getVariantClicksHistory()[variantId];

export const trackVariantClickHistory = ({
  variantId,
  queryId,
  position
}: {
  variantId: string;
  queryId: string;
  position?: number;
}) => {
  localStorage.setItem(
    'variantClicksHistory',
    JSON.stringify({
      ...getVariantClicksHistory(),
      [variantId]: {
        queryId,
        position
      }
    })
  );
};
