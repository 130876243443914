import { gql } from '@apollo/client';
import React, { useMemo } from 'react';
import { useBreakpoint } from '@moda/om';
import classNames from 'classnames';
import { compact } from '@moda/portal-stanchions';
import { any } from 'ramda';
import { PaginatedSlider } from '../../../../PaginatedSlider';
import { VariantCellSizeSliderFragment } from '../../../../../generated/types';
import { getActiveVariantBy } from '../../../../../lib/getActiveVariantBy';
import { VariantCellSizeSliderButton } from './VariantCellSizeSliderButton';

import './VariantCellSizeSlider.scss';

export const VARIANT_CELL_SIZE_SLIDER_VARIANT_FRAGMENT = gql`
  fragment VariantCellSizeSliderVariantFragment on VariantData {
    id
    inventory {
      sku
      size
      canSell: can_sell
    }
  }
`;

export const VARIANT_CELL_SIZE_SLIDER_FRAGMENT = gql`
  fragment VariantCellSizeSliderFragment on Variant {
    id
    masterVariant: master_variants_data {
      ...VariantCellSizeSliderVariantFragment
    }
    otherVariants: other_variants_data {
      ...VariantCellSizeSliderVariantFragment
    }
  }
  ${VARIANT_CELL_SIZE_SLIDER_VARIANT_FRAGMENT}
`;

interface Props {
  className?: string;
  variant: VariantCellSizeSliderFragment;
  activeVariantId: string;
  selectedSize?: string;
  onClick(size: string): void;
  onSelectSize(size: string): void;
}

const DESKTOP_PER_PAGE = 3;
const MOBILE_PER_PAGE = 2;

export const VariantCellSizeSlider: React.FC<Props> = ({
  variant,
  activeVariantId,
  selectedSize,
  onSelectSize,
  onClick,
  className
}) => {
  const activeVariant = useMemo(
    () => getActiveVariantBy(variant, 'id', activeVariantId),
    [activeVariantId, variant]
  );

  const inventory = compact(activeVariant.inventory);

  // Only show one size at a time if the size names are too long.
  const TOO_LONG = 7;
  const hasLongName = any(sku => sku.size != null && sku.size.length >= TOO_LONG, inventory);

  const isDesktop = useBreakpoint({ gt: 'sm' }).matches;

  return (
    <PaginatedSlider
      className={classNames('VariantCellSizeSlider', className)}
      per={hasLongName ? 1 : isDesktop ? DESKTOP_PER_PAGE : MOBILE_PER_PAGE}
    >
      {inventory.map(
        ({ sku, size, canSell }) =>
          size && (
            <div key={sku || size} className="VariantCellSizeSlider__cell">
              <VariantCellSizeSliderButton
                size={size}
                selected={Boolean(canSell && selectedSize && selectedSize === size)}
                disabled={!canSell}
                onClick={onClick}
                onSelect={onSelectSize}
              />
            </div>
          )
      )}
    </PaginatedSlider>
  );
};
