export const isSpectacularGift = (variant: {
  category: string[];
  subcategory: string[];
  shopAttributes: string[];
}) => {
  return (
    variant.category.includes('EXPERIENCES') ||
    variant.category.includes('SPECTACULAR_PRODUCTS') ||
    variant.subcategory.includes('EXPERIENCES') ||
    variant.subcategory.includes('SPECTACULAR_PRODUCTS') ||
    variant.shopAttributes.includes('gift=spectacular-gifts')
  );
};
