import React from 'react';
import classNames from 'classnames';
import { PlaceholderCell } from './GridCell/PlaceholderCell';

import './Grid.scss';

export const PLACEHOLDERS_AMOUNT = 20;
export const PLACEHOLDERS = new Array(PLACEHOLDERS_AMOUNT)
  .fill(undefined)
  .map((_, index) => <PlaceholderCell key={index} />);

export interface GridProps extends React.HTMLAttributes<HTMLDivElement> {
  loading?: boolean;
}

export const Grid: React.FC<GridProps> = ({ children, className, loading, ...rest }) => {
  const cells = loading ? PLACEHOLDERS : children;

  return (
    <section
      className={classNames('Grid', className, {
        'Grid--loading': loading
      })}
      {...rest}
    >
      {cells}
    </section>
  );
};
