import { gql } from '@apollo/client';
import React, { useMemo } from 'react';
import classNames from 'classnames';
import { getActiveVariantBy } from '../../../../../lib/getActiveVariantBy';
import { ColorSelector, COLOR_SELECTOR_FRAGMENT } from '../../../../ColorSelector';
import { VariantCellColorSelectorFragment } from '../../../../../generated/types';

import './VariantCellColorSelector.scss';

export const VARIANT_CELL_COLOR_SELECTOR_FRAGMENT = gql`
  fragment VariantCellColorSelectorFragment on Variant {
    ...ColorSelectorFragment
  }
  ${COLOR_SELECTOR_FRAGMENT}
`;

type Props = {
  variant: VariantCellColorSelectorFragment;
  activeVariantId: string;
  onSelectColor(color: string): void;
  className?: string;
  href: string;
};

export const VariantCellColorSelector: React.FC<Props> = ({
  className,
  variant,
  onSelectColor,
  activeVariantId,
  href
}) => {
  const { color, customColorName } = useMemo(
    () => getActiveVariantBy(variant, 'id', activeVariantId),
    [activeVariantId, variant]
  );

  return (
    <ColorSelector
      size="small"
      layout="flex"
      maxVisibleColors={4}
      className={classNames('VariantCellColorSelector', className)}
      variant={variant}
      onSelect={onSelectColor}
      selected={customColorName || color}
      href={href}
    />
  );
};
