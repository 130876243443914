import React, { useMemo } from 'react';
import { Breakpoint } from '@moda/om';

const MOBILE = 'mobile';
const DESKTOP = 'desktop';

export interface Props {
  platforms: string[];
  children: React.ReactNode;
}

export const RestrictPlatform: React.FC<Props> = ({ platforms, children }) => {
  const mobile = useMemo(() => platforms.length === 1 && platforms[0] === MOBILE, [platforms]);
  const desktop = useMemo(() => platforms.length === 1 && platforms[0] === DESKTOP, [platforms]);

  if (mobile) return <Breakpoint lt="md">{children}</Breakpoint>;
  if (desktop) return <Breakpoint gt="md">{children}</Breakpoint>;

  // Available on all platforms

  return <>{children}</>;
};
