import React from 'react';
import classNames from 'classnames';
import { Link } from '../Link';
import './Pill.scss';

interface Props extends React.HTMLAttributes<HTMLLinkElement> {
  text: string;
  url: string;
}

export const Pill: React.FC<Props> = ({ text, url, className }) => {
  return (
    <Link to={url} className={classNames('Pill', className)}>
      <div>{text}</div>
    </Link>
  );
};
