import { useBreakpoint } from '@moda/om';

const MOBILE_SIZE = {
  maxWidth: 200,
  maxHeight: 318
};

const DEKSTOP_SIZE = {
  maxWidth: 340,
  maxHeight: 540
};

export const useGridCellImageSize = () => {
  const { matches: isMobile } = useBreakpoint({ lt: 'xs' });

  return isMobile ? MOBILE_SIZE : DEKSTOP_SIZE;
};
