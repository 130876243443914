import React from 'react';
import { Clickable } from '@moda/om';
import classNames from 'classnames';
import './CarouselDot.scss';

interface Props {
  index: number;
  active: boolean;
  onClick(index: number): void;
}

export const CarouselDot: React.FC<Props> = ({ index, active, onClick }) => {
  const handleClick = React.useCallback(() => onClick(index), [index, onClick]);
  const label = `Show slide number ${index + 1}`;

  return (
    <Clickable
      aria-label={label}
      className={classNames('CarouselDot', {
        'CarouselDot--active': active
      })}
      onClick={handleClick}
    >
      <div className="CarouselDot__dot" />
    </Clickable>
  );
};
