import { useBreakpoint } from '@moda/om';
import { useMemo } from 'react';

export const useLazyCells = <T>(cells: T[]) => {
  const INITIAL_MOBILE_CELL_COUNT = 4;
  const INITIAL_DESKTOP_CELL_COUNT = 10;

  const { matches: isMobile } = useBreakpoint({ lt: 'xs' });
  const initialCellsCount = isMobile ? INITIAL_MOBILE_CELL_COUNT : INITIAL_DESKTOP_CELL_COUNT;

  const initialCells = useMemo(() => cells.slice(0, initialCellsCount), [cells, initialCellsCount]);

  const lazyCells = useMemo(() => cells.slice(initialCellsCount), [cells, initialCellsCount]);

  return { initialCells, lazyCells };
};
