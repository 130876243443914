import { ApolloError, useMutation } from '@apollo/client';
import { useBreakpoint } from '@moda/om';
import { deepRemoveKeys } from '@moda/portal-stanchions';
import { StatusCodes } from 'http-status-codes';
import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useEmailSignupPhoneNumber } from '../../components/EmailSignup/useEmailSignup/useEmailSignupPhoneNumber';
import { Gender } from '../../generated/types';
import { reportError } from '../../lib/reportError';
import { tc } from '../../lib/trackingContext';
import { useEmailVerification } from '../useEmailVerification';
import { usePhoneNumberValidation } from '../usePhoneNumberValidation';
import { SubscribeArgs } from './useEmailSubscribe';
import {
  AttentiveSignUpSource,
  ATTENTIVE_ERROR_RESPONSE_CODES,
  EmailSubscribeMutation,
  EmailSubscribeMutationVariables,
  EMAIL_SUBSCRIBE_MUTATION,
  RestEmailSubscribeTableAttentiveResponseTableContent
} from './useEmailSubscribe.rest';

const isAttentiveErrorStatusCode = (statusCode: StatusCodes) =>
  ATTENTIVE_ERROR_RESPONSE_CODES.includes(statusCode);

const isAttentiveStatusCodeAccepted = (statusCode: StatusCodes) =>
  statusCode === StatusCodes.ACCEPTED;

export const useAttentiveSubscribe = () => {
  const { vertical = 'women' } = useParams<{
    vertical: Gender | undefined;
    trunkshowSlug: string | undefined;
    designerSlug: string | undefined;
  }>();
  const { enabled: phoneNumberEnabled, phone, setPhone } = useEmailSignupPhoneNumber();

  const isMobile = useBreakpoint({ lt: 'md' }).matches;

  const { isValid: isEmailValid, error: mailValidationError } = useEmailVerification();
  const { isValid: isPhoneNumberValid, error: phoneNumberValidationError } =
    usePhoneNumberValidation();

  const [mutate, { data, loading, error: mojoError }] = useMutation<
    EmailSubscribeMutation,
    EmailSubscribeMutationVariables
  >(EMAIL_SUBSCRIBE_MUTATION);

  const attentiveError = useMemo(() => {
    if (!data?.emailSubscribe.table.attentiveResponse) return;

    const { statusCode } = data.emailSubscribe.table.attentiveResponse.table;

    if (!isAttentiveErrorStatusCode(Number(statusCode))) return;

    try {
      const parsedError: RestEmailSubscribeTableAttentiveResponseTableContent = JSON.parse(
        data.emailSubscribe.table.attentiveResponse.table.content
      );
      return parsedError;
    } catch {
      return data.emailSubscribe.table.attentiveResponse.table.content;
    }
  }, [data]);

  const handleError = useCallback(
    (caughtError: ApolloError) => reportError('ATTENTIVE_SUBSCRIBE', caughtError),
    []
  );

  const [isSubscribed, setIsSubscribed] = useState(false);

  const defaultSource: AttentiveSignUpSource = isMobile ? 'mobile' : 'desktop';

  const subscribe = useCallback(
    ({ email, label, phone = '', source = defaultSource }: SubscribeArgs) => {
      if (!isEmailValid(email)) return;
      if (!isPhoneNumberValid(phone)) return;

      const filteredPhoneNumber = phone.replace(/[^0-9]/g, '');

      mutate({
        variables: {
          input: {
            user: {
              email,
              gender: Gender[vertical],
              phone: filteredPhoneNumber
            },
            label,
            category: 'Marketing Signup',
            sign_up_source_target: source
          }
        }
      })
        .then(({ data }) => {
          if (!data) return;

          if (!data.emailSubscribe.table.attentiveResponse) {
            setIsSubscribed(true);
            return;
          }

          const { statusCode } = data.emailSubscribe.table.attentiveResponse.table;

          tc.track('Attentive Signup Submitted', {
            addToTrackingEvent: {
              attentiveResponse: {
                statusCode,
                content: deepRemoveKeys(
                  data.emailSubscribe.table.attentiveResponse.table.content,
                  '__typename'
                )
              }
            }
          });

          if (isAttentiveStatusCodeAccepted(Number(statusCode))) {
            setIsSubscribed(true);
          }
        })
        .catch(handleError);
    },
    [handleError, isEmailValid, defaultSource, isPhoneNumberValid, mutate, vertical]
  );

  const error = attentiveError || (mojoError && 'Unable to subscribe you at this time');

  return {
    subscribe,
    loading,
    error,
    mailValidationError,
    attentiveError,
    isSubscribed,
    phoneNumberEnabled,
    phone,
    setPhone,
    isEmailValid,
    isPhoneNumberValid,
    phoneNumberValidationError
  };
};
