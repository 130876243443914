import { gql } from '@apollo/client';
import classNames from 'classnames';
import React from 'react';
import { SmsPumoModuleSlidesFragment } from '../../../generated/types';
import { PumoModuleSlide, PUMO_MODULE_SLIDE_FRAGMENT } from '../../PumoModuleSlide';
import { SwiperCarousel } from '../../Swiper/SwiperCarousel';

export const SMS_PUMO_MODULE_SLIDES_FRAGMENT = gql`
  fragment SmsPumoModuleSlidesFragment on SmsPumoModule {
    id
    slides {
      ...PumoModuleSlideFragment
    }
  }
  ${PUMO_MODULE_SLIDE_FRAGMENT}
`;

interface Props {
  smsPumoModule: SmsPumoModuleSlidesFragment;
}

export const SmsPumoModuleSlides: React.FC<Props> = ({ smsPumoModule: { slides, id } }) => {
  if (slides.length > 1) {
    return (
      <SwiperCarousel
        autoplay={{
          delay: 4000,
          disableOnInteraction: false
        }}
        className={classNames('SmsPumoModuleSlides')}
        identifier={`SinglePumoModule__${id}`}
        loop
        speed={1400}
      >
        {slides.map((slide, index) => (
          <PumoModuleSlide key={slide.id} slide={slide} index={index} />
        ))}
      </SwiperCarousel>
    );
  }

  if (slides.length === 1) return <PumoModuleSlide key={slides[0].id} slide={slides[0]} />;

  return null;
};
