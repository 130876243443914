import { ONE_HUNDRED_PERCENT, TEN_PERCENT, ROUNDING_THRESHOLD } from '../../constants';

/**
 * Leaving this as is simply to mirror current behavior in Mojo, but this is weird.
 * We get the percentage, and then round further but only if the last digit
 * is a 9 or 1?
 */
export const getRoundedMarkdownPercent = ({
  originalPrice,
  currentPrice
}: {
  originalPrice: number;
  currentPrice: number;
}) => {
  const markdownPercent = Math.round(
    ((originalPrice - currentPrice) / originalPrice) * ONE_HUNDRED_PERCENT
  );

  if (markdownPercent % TEN_PERCENT === 1) return markdownPercent - 1;
  if (markdownPercent % TEN_PERCENT === ROUNDING_THRESHOLD) return markdownPercent + 1;

  return markdownPercent;
};
