import { gql } from '@apollo/client';
import React from 'react';
import './HomePageHeaderListItem.scss';
import { CallToAction } from '../CallToAction';
import { HomePageHeaderListItemFragment } from '../../generated/types';
import { PumoModuleImage, PUMO_MODULE_IMAGE_FRAGMENT } from '../PumoModuleImage';
import { Link } from '../Link';

export interface Props {
  listItem: HomePageHeaderListItemFragment;
}

export const HOMEPAGE_HEADER_LIST_ITEM_FRAGMENT = gql`
  fragment HomePageHeaderListItemFragment on HomePageHeaderPumoModuleListItem {
    ctaUrl
    featureImage {
      ...PumoModuleImageFragment
    }
    headlineText {
      body
      size
    }
    anchorText {
      body
    }
  }
  ${PUMO_MODULE_IMAGE_FRAGMENT}
`;

export const HomePageHeaderListItem: React.FC<Props> = ({ listItem }) => {
  return (
    <Link className="HomePageHeaderListItem" to={listItem.ctaUrl || '#'}>
      {listItem.featureImage && (
        <PumoModuleImage
          className="HomePageHeaderListItem__image"
          cover
          pumoModuleImage={listItem.featureImage}
        />
      )}

      <div className="HomePageHeaderListItem__text">
        {listItem.headlineText?.body && (
          <h4 className="HomePageHeaderListItem__headline">{listItem.headlineText.body}</h4>
        )}

        {listItem.anchorText?.body && <CallToAction>{listItem.anchorText.body}</CallToAction>}
      </div>
    </Link>
  );
};
