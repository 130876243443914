import React, { useMemo } from 'react';
import { Stack, Text } from '@moda/om';
import { CarouselCellFragment, VariantCellFragment } from '../../generated/types';
import { useModuleTracking } from '../../hooks/useModuleTracking';
import { AddSetToBagButton } from '../AddToBagButton';
import { NormalizePumoStyles } from '../NormalizePumoStyles';
import { DividerModule, DividerModuleProps } from '../DividerPumoModule';
import { CAROUSEL_DEFAULT_CONFIG, ProductSwiperCarousel } from '../Swiper';
import { CarouselCell } from '../Grid';
import './RecommendationsCarousel.scss';

interface Props {
  identifier: string;
  loading?: boolean;
  error?: Error;
  divider: DividerModuleProps;
  description?: string | null;
  cells: CarouselCellFragment[];
  enableAddSetToBag?: boolean;
}

export const RecommendationsCarousel: React.FC<Props> = ({
  loading,
  error,
  divider,
  description,
  cells,
  identifier,
  enableAddSetToBag
}) => {
  const variantCells = useMemo(
    () => cells.filter(cell => cell.__typename === 'Variant') as VariantCellFragment[],
    [cells]
  );

  const { ref, onClick } = useModuleTracking({
    name: divider.text,
    attachClickHandler: false,
    variants: variantCells
  });

  if (loading || error || cells.length === 0) return null;

  return (
    <div className="RecommendationsCarousel" ref={ref}>
      <Stack space={1}>
        <NormalizePumoStyles>
          <DividerModule {...divider} />
        </NormalizePumoStyles>
        {description && (
          <Text treatment="h6" className="RecommendationsCarousel__description">
            {description}
          </Text>
        )}
      </Stack>
      <ProductSwiperCarousel
        identifier={identifier}
        {...CAROUSEL_DEFAULT_CONFIG}
        onClick={(swiper, event) => onClick(event)}
      >
        {cells.map(carouselCell => (
          <CarouselCell
            key={carouselCell.__typename + carouselCell.id}
            carouselCell={carouselCell}
            moduleName={divider.text}
          />
        ))}
      </ProductSwiperCarousel>
      {enableAddSetToBag && (
        <AddSetToBagButton
          className="RecommendationsCarousel__add-to-bag"
          variants={variantCells}
        />
      )}
    </div>
  );
};
