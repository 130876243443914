import { gql } from '@apollo/client';
import React from 'react';
import { colors } from '@moda/tokens';
import { useBreakpoint } from '@moda/om';
import { UseEditorialTextStyleFragment } from '../../generated/types';
import { ONE_HUNDRED_PERCENT } from '../../constants';

export const EDITORIAL_BG_COLORS = {
  'Black-80': `rgba(0, 0, 0, .8)`,
  'Burnt-Orange': colors.all['burnt-orange'],
  'Cornflower-Blue': colors.all['cornflower-blue'],
  'Forest-Green': colors.all['forest-green'],
  'Klein-Blue': colors.all['klein-blue'],
  'Light-Grey': colors.all.noise,
  'Moss-Green': colors.all['moss-green'],
  'White-90': `rgba(255, 255, 255, .9)`,
  'Dark-Fuchsia': colors.all['dark-fuchsia'],
  Black: colors.all.ink,
  Blue: colors.all['burnt-orange'],
  Blush: colors.all.blush,
  Brick: colors.all.brick,
  Canary: colors.all.canary,
  Coral: colors.all.coral,
  Cream: colors.all.cream,
  Fuchsia: colors.all.fuchsia,
  Goldenrod: colors.all.goldenrod,
  Ivory: colors.all.ivory,
  Lilac: colors.all.lilac,
  Pink: colors.all.strawberry,
  Seafoam: colors.all.seafoam,
  Teal: colors.all.teal,
  Transparent: 'transparent',
  White: colors.all.snow
} as const;

export type EditorialBgColor = keyof typeof EDITORIAL_BG_COLORS;

export const EDITORIAL_POSITIONS = {
  'Top Left': {
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },
  'Top Center': {
    justifyContent: 'center',
    alignItems: 'flex-start'
  },
  'Top Right': {
    justifyContent: 'flex-end',
    alignItems: 'flex-start'
  },
  'Center Left': {
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  Center: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  'Center Right': {
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  'Bottom Left': {
    justifyContent: 'flex-start',
    alignItems: 'flex-end'
  },
  'Bottom Center': {
    justifyContent: 'center',
    alignItems: 'flex-end'
  },
  'Bottom Right': {
    justifyContent: 'flex-end',
    alignItems: 'flex-end'
  }
};

export type EditorialPosition = keyof typeof EDITORIAL_POSITIONS;

export const NUMBER_OF_BOOTSTRAP_COLUMNS = 12;

export const USE_EDITORIAL_TEXT_STYLE_FRAGMENT = gql`
  fragment UseEditorialTextStyleFragment on SlideText {
    editorialPosition
    editorialBgColor
    editorialWidth
    editorialEdgeMargin
    textColor
    textAlign
    verticalPadding
    horizontalPadding
  }
`;

export const useEditorialTextStyle = (
  textStyle: UseEditorialTextStyleFragment
): React.CSSProperties => {
  const {
    editorialPosition,
    editorialEdgeMargin,
    editorialWidth,
    verticalPadding,
    horizontalPadding,
    textColor,
    textAlign,
    editorialBgColor
  } = textStyle;

  const isDesktop = useBreakpoint({ gt: 'md' }).matches;

  // TODO: map px values onto spacing scale
  return {
    ...EDITORIAL_POSITIONS[editorialPosition as EditorialPosition],
    margin: `${editorialEdgeMargin}px`,
    // Pumo assumes the width is controlled by Bootstrap, using 12 columns. We are converting to % here
    width: isDesktop
      ? `${(ONE_HUNDRED_PERCENT / NUMBER_OF_BOOTSTRAP_COLUMNS) * editorialWidth}%`
      : undefined,
    padding: `${verticalPadding || 0}px ${horizontalPadding || 0}px`,
    color: textColor,
    textAlign,
    backgroundColor: `${EDITORIAL_BG_COLORS[editorialBgColor as EditorialBgColor]}`
  };
};
