import React from 'react';
import { LazyLoad, EnableLazyLoad } from '../LazyLoad';
import { useLazyCells } from '../../hooks/useLazyCells';
import { PlaceholderCell } from './GridCell';
import { Grid, GridProps } from './Grid';

type LazyGridProps = GridProps & {
  children: React.ReactNode[];
  onLazyCellsLoad?: () => void;
};

export const LazyGrid: React.FC<LazyGridProps> = ({ onLazyCellsLoad, children, ...rest }) => {
  const { initialCells, lazyCells } = useLazyCells(children);

  return (
    <Grid {...rest}>
      {initialCells}
      <EnableLazyLoad>
        {lazyCells.map((cell, index) => (
          <LazyLoad
            key={(cell as React.ReactElement).key}
            fallback={
              <PlaceholderCell data-variant-pk={(cell as React.ReactElement).props?.variant?.id} />
            }
            onLoad={index === 0 ? onLazyCellsLoad : undefined}
          >
            {cell}
          </LazyLoad>
        ))}
      </EnableLazyLoad>
    </Grid>
  );
};
