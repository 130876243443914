import React, { useMemo } from 'react';
import { gql } from '@apollo/client';
import { compact } from '@moda/portal-stanchions';
import { VariantCellImageGalleryFragment } from '../../../../../generated/types';
import { getActiveVariantBy } from '../../../../../lib/getActiveVariantBy';
import { ImageGallery } from '../../../../ImageGallery';
import { useGridCellImageSize } from '../../useGridCellImageSize';

export const VARIANT_CELL_IMAGE_GALLERY_VARIANT_FRAGMENT = gql`
  fragment VariantCellImageGalleryVariantFragment on VariantData {
    id
    primaryImageUrls: primary_image_urls {
      src: medium
    }
    alternateImageUrls: alternate_image_urls {
      src: medium
    }
  }
`;

export const VARIANT_CELL_IMAGE_GALLERY_FRAGMENT = gql`
  fragment VariantCellImageGalleryFragment on Variant {
    id
    name
    masterVariant: master_variants_data {
      id
      ...VariantCellImageGalleryVariantFragment
    }
    otherVariants: other_variants_data {
      id
      ...VariantCellImageGalleryVariantFragment
    }
  }
  ${VARIANT_CELL_IMAGE_GALLERY_VARIANT_FRAGMENT}
`;

interface Props {
  variant: VariantCellImageGalleryFragment;
  activeVariantId: string;
  active?: boolean;
}

export const VariantCellImageGallery: React.FC<Props> = ({
  variant,
  activeVariantId,
  active = false
}) => {
  const { maxHeight, maxWidth } = useGridCellImageSize();

  const activeVariant = useMemo(
    () => getActiveVariantBy(variant, 'id', activeVariantId),
    [activeVariantId, variant]
  );

  const srcs = useMemo(
    () =>
      compact(
        compact([activeVariant.primaryImageUrls, ...activeVariant.alternateImageUrls]).map(
          ({ src }) => src
        )
      ),
    [activeVariant.alternateImageUrls, activeVariant.primaryImageUrls]
  );

  const images = useMemo(
    () => srcs.map(src => ({ src, title: variant.name })),
    [srcs, variant.name]
  );

  return <ImageGallery images={images} active={active} maxHeight={maxHeight} maxWidth={maxWidth} />;
};
