import React from 'react';
import './TimeSection.scss';

interface TimeSectionProps {
  amountToShow: string;
  units: string;
}
export const TimeSection: React.FC<TimeSectionProps> = ({ amountToShow, units }) => {
  return (
    <div className="TimeSection">
      <div className="TimeSection__number"> {amountToShow} </div>
      <div className="TimeSection__time"> {units} </div>
    </div>
  );
};
