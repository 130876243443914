import { gql, TypedDocumentNode } from '@apollo/client';
import { CART_FRAGMENT, Cart, CartVersion } from '../useCart/cart.rest';

export type AddToBagMutationVariables = {
  input: {
    data: {
      attributes: {
        availability: string;
        cart_id: string | null;
        country_code: string;
        customization: string;
        position: number;
        product_id: number;
        size: string;
        quantity?: number;
        target: CartVersion;
        trunkshow_id: string | null;
        variant_id: string;
      };
      type: 'line_items';
    };
  };
};

export type AddToBagMutation = {
  addToBag: {
    data: Cart[];
  };
};

export const ADD_TO_BAG_MUTATION: TypedDocumentNode<AddToBagMutation, AddToBagMutationVariables> =
  gql`
    mutation AddToBagMutation($input: AddToBagInput) {
      addToBag(input: $input)
        @rest(type: "RestAddToBag", endpoint: "moda", path: "/v3.5/line_items", method: "POST") {
        data @type(name: "RestUserCartData") {
          ...CartFragment
        }
      }
    }
    ${CART_FRAGMENT}
  `;
