import React from 'react';
import { RecommendationsVariantFragment } from '../../generated/types';
import {
  useFavoritedProducts,
  useRecentlyViewed,
  useSimilarProducts,
  useLookingSimilar,
  useTopTrunkshows,
  useVariantsFromDesigner
} from '../../hooks/useRecommendations';
import { EnableLazyLoad } from '../LazyLoad';
import { DividerModuleProps } from '../DividerPumoModule';
import { RecommendationsCarousel } from './RecommendationsCarousel';

export const ALGORITHMS = {
  TOP_TRUNKSHOWS: 'top trunkshows',
  FAVORITES: 'favorites',
  SIMILAR_PRODUCTS: 'similar products',
  LOOKING_SIMILAR: 'looking similar',
  RECENTLY_VIEWED: 'recently viewed',
  FROM_THIS_DESIGNER: 'from this designer'
};

interface RecommendationProps {
  divider: DividerModuleProps;
  variant?: RecommendationsVariantFragment;
  identifier: string;
}

export const Favorites: React.FC<RecommendationProps> = ({ divider, identifier }) => {
  const { loading, error, variants } = useFavoritedProducts();
  return (
    <RecommendationsCarousel
      identifier={`Favorites-${identifier}`}
      loading={loading}
      error={error}
      divider={divider}
      cells={variants}
    />
  );
};

export const SimilarProducts: React.FC<RecommendationProps> = ({
  divider,
  variant,
  identifier
}) => {
  const { loading, error, variants } = useSimilarProducts({ variant });

  return (
    <RecommendationsCarousel
      identifier={`SimilarProducts-${identifier}`}
      loading={loading}
      error={error}
      divider={divider}
      cells={variants}
    />
  );
};

export const LookingSimilar: React.FC<RecommendationProps> = ({ divider, identifier }) => {
  const { loading, error, variants } = useLookingSimilar({ variantId: identifier });

  return (
    <RecommendationsCarousel
      identifier={`LookingSimilar-${identifier}`}
      loading={loading}
      error={error}
      divider={divider}
      cells={variants}
    />
  );
};

export const TopTrunkshows: React.FC<RecommendationProps> = ({ divider, identifier }) => {
  const { loading, error, trunkshows } = useTopTrunkshows();
  return (
    <RecommendationsCarousel
      identifier={`TopTrunkshows-${identifier}`}
      loading={loading}
      error={error}
      divider={divider}
      cells={trunkshows}
    />
  );
};

export const RecentlyViewed: React.FC<RecommendationProps> = ({ divider, identifier }) => {
  const { loading, error, variants } = useRecentlyViewed();
  return (
    <RecommendationsCarousel
      identifier={`RecentlyViewed-${identifier}`}
      loading={loading}
      error={error}
      divider={divider}
      cells={variants}
    />
  );
};

export const FromThisDesigner: React.FC<RecommendationProps> = ({
  divider,
  identifier,
  variant
}) => {
  const { loading, error, variants } = useVariantsFromDesigner(variant);
  return (
    <RecommendationsCarousel
      identifier={`FromThisDesigner-${identifier}`}
      loading={loading}
      error={error}
      divider={divider}
      cells={variants}
    />
  );
};

const ALGORITHM_COMPONENT_MAP = {
  [ALGORITHMS.TOP_TRUNKSHOWS]: TopTrunkshows,
  [ALGORITHMS.FAVORITES]: Favorites,
  [ALGORITHMS.SIMILAR_PRODUCTS]: SimilarProducts,
  [ALGORITHMS.LOOKING_SIMILAR]: LookingSimilar,
  [ALGORITHMS.RECENTLY_VIEWED]: RecentlyViewed,
  [ALGORITHMS.FROM_THIS_DESIGNER]: FromThisDesigner
};

interface Props {
  algorithm: string | null;
  variant?: RecommendationsVariantFragment;
  divider: DividerModuleProps;
  identifier: string;
}

export const RecommendationsModule: React.FC<Props> = ({
  algorithm,
  variant,
  divider,
  identifier
}) => {
  if (!algorithm) return null;

  const RecommendationComponent = ALGORITHM_COMPONENT_MAP[algorithm];

  if (!RecommendationComponent) return null;

  return (
    <EnableLazyLoad>
      <RecommendationComponent divider={divider} variant={variant} identifier={identifier} />
    </EnableLazyLoad>
  );
};
