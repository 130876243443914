import { gql } from '@apollo/client';
import React from 'react';
import { RecommendationsPumoModuleFragment } from '../../generated/types';
import { useModuleTracking } from '../../hooks/useModuleTracking';
import { RecommendationsModule } from './RecommendationsModule';

export const RECOMMENDATIONS_PUMO_MODULE_FRAGMENT = gql`
  fragment RecommendationsPumoModuleFragment on RecommendationsPumoModule {
    name
    dividerType
    dividerText
    dividerPadding
    algorithm
    id
  }
`;

interface Props {
  recommendationsPumoModule: RecommendationsPumoModuleFragment;
}

export const RecommendationsPumoModule: React.FC<Props> = ({ recommendationsPumoModule }) => {
  const { dividerType, dividerText, dividerPadding, algorithm, name, id } =
    recommendationsPumoModule;

  const { ref } = useModuleTracking({ name });

  return (
    <div ref={ref} className="RecommendationsPumoModule">
      <RecommendationsModule
        algorithm={algorithm}
        divider={{ layoutType: dividerType, text: dividerText, verticalPadding: dividerPadding }}
        identifier={id.toString()}
      />
    </div>
  );
};
