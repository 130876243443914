import { gql } from '@apollo/client';
import React from 'react';
import { useModuleTracking } from '../../hooks/useModuleTracking';
import { PillsPumoModuleFragment } from '../../generated/types';
import { EDITORIAL_BG_COLORS, EditorialBgColor } from '../PumoModuleEditorialText';
import { RestrictPlatform } from '../RestrictPlatform';
import { NormalizePumoStyles } from '../NormalizePumoStyles';
import { Pill } from './Pill';

import './PillsPumoModule.scss';

export interface Props {
  pillsPumoModule: PillsPumoModuleFragment;
}

export const PILLS_PUMO_MODULE_FRAGMENT = gql`
  fragment PillsPumoModuleFragment on PillsPumoModule {
    name
    bgColor
    pills {
      url
      text
    }
    platform
  }
`;

export const PillsPumoModule: React.FC<Props> = ({
  pillsPumoModule: { bgColor, pills, name, platform }
}) => {
  const backgroundColor = EDITORIAL_BG_COLORS[bgColor as EditorialBgColor];

  const { ref } = useModuleTracking({ name });

  return (
    <RestrictPlatform platforms={platform}>
      <div ref={ref}>
        <NormalizePumoStyles className="PillsPumoModule" style={{ backgroundColor }}>
          {pills.map(
            ({ url, text }) =>
              url &&
              text && (
                <Pill className="PillsPumoModule__pill" key={url + text} url={url} text={text} />
              )
          )}
        </NormalizePumoStyles>
      </div>
    </RestrictPlatform>
  );
};
