import React from 'react';
import { gql } from '@apollo/client';
import { AspectRatioBox } from '@moda/om';
import { EditorialVideoPlacement, VideoPumoModuleFragment } from '../../generated/types';
import { PumoModuleSlide, PUMO_MODULE_SLIDE_FRAGMENT } from '../PumoModuleSlide';
import { EditorialVideo, EDITORIAL_VIDEO_FRAGMENT } from '../EditorialVideo';

import './VideoPumoModule.scss';

export const VIDEO_PUMO_MODULE_FRAGMENT = gql`
  fragment VideoPumoModuleFragment on VideoPumoModule {
    id
    name
    video {
      ...EditorialVideoFragment
    }
    slides {
      ...PumoModuleSlideFragment
    }
  }
  ${EDITORIAL_VIDEO_FRAGMENT}
  ${PUMO_MODULE_SLIDE_FRAGMENT}
`;

interface Props {
  videoModule: VideoPumoModuleFragment;
}

export const VideoPumoModule: React.FC<Props> = ({ videoModule: { video, slides } }) => {
  if (!video.width) return null;

  if (!video.height) return null;

  const slide =
    slides[0]?.featureImage != null || slides[0]?.slideText?.taglineText?.body ? slides[0] : null;

  if (!slide) return <EditorialVideo video={video} />;

  return (
    <div className="VideoPumoModule">
      {video.placement === EditorialVideoPlacement.LEFT && <EditorialVideo video={video} />}

      <AspectRatioBox aspectWidth={video.width} aspectHeight={video.height}>
        <PumoModuleSlide slide={slide} />
      </AspectRatioBox>

      {video.placement === EditorialVideoPlacement.RIGHT && <EditorialVideo video={video} />}
    </div>
  );
};
