import React, { useMemo, useCallback, useState } from 'react';
import { gql } from '@apollo/client';
import { Stack, Text } from '@moda/om';
import classNames from 'classnames';
import { useParams, Link } from 'react-router-dom';
import isTouchDevice from 'is-touch-device';
import { compact, SECONDS_TO_MILLISECONDS } from '@moda/portal-stanchions';
import { TrunkshowCellFragment } from '../../../../generated/types';
import { hrefFor } from '../../../../routers';
import {
  useHasAccessToProductOrTrunkshow,
  HAS_ACCESS_TO_TRUNKSHOW_FRAGMENT
} from '../../../../hooks/useHasAccessToProductOrTrunkshow';
import { ImageGallery } from '../../../ImageGallery';
import { RelativeTime } from '../../../RelativeTime';

import './TrunkshowCell.scss';

export const TRUNKSHOW_CELL_FRAGMENT = gql`
  fragment TrunkshowCellFragment on Trunkshow {
    id
    slug
    name
    seasonName: season_name
    activeEndAt: active_end_i
    primaryImageUrls: primary_image_urls {
      src: main
    }
    previewImageUrls: preview_image_urls
    ...HasAccessToTrunkshowFragment
  }
  ${HAS_ACCESS_TO_TRUNKSHOW_FRAGMENT}
`;

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  trunkshow: TrunkshowCellFragment;
}

export enum Mode {
  Resting,
  Active
}

export const TrunkshowCell: React.FC<Props> = ({ className, trunkshow }) => {
  const { vertical = 'women' } = useParams<{ vertical?: string }>();
  const [mode, setMode] = useState(Mode.Resting);

  const handleMouseEnter = useCallback(() => {
    if (isTouchDevice()) return;
    setMode(Mode.Active);
  }, []);
  const handleMouseLeave = useCallback(() => {
    if (isTouchDevice()) return;
    setMode(Mode.Resting);
  }, []);

  const { hasAccess } = useHasAccessToProductOrTrunkshow(trunkshow);

  const href = hrefFor.TrunkshowPage({
    vertical: vertical,
    trunkshowId: trunkshow.id,
    trunkshowSlug: trunkshow.slug || trunkshow.id
  });

  const imageSrcs = useMemo(
    () => compact([trunkshow.primaryImageUrls?.src, ...trunkshow.previewImageUrls]),
    [trunkshow]
  );

  const images = useMemo(
    () => imageSrcs.map(src => ({ src, title: trunkshow.name })),
    [imageSrcs, trunkshow]
  );

  if (!hasAccess) return null;

  if (trunkshow.activeEndAt * SECONDS_TO_MILLISECONDS < Date.now()) return null;

  return (
    <Link
      to={href || ''}
      data-pk={trunkshow.id}
      className={classNames('TrunkshowCell', className)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      data-trunkshow-pk={trunkshow.id}
    >
      <ImageGallery images={images} active={mode === Mode.Active} maxHeight={540} maxWidth={340} />
      {trunkshow.activeEndAt && trunkshow.name && (
        <Stack className="TrunkshowCell__details" space={1} alignItems="center">
          <Text treatment="eyebrow">
            <RelativeTime timestamp={trunkshow.activeEndAt}>Left</RelativeTime>
          </Text>
          <Text treatment="h5" family="serif">
            {trunkshow.name}
          </Text>
        </Stack>
      )}
    </Link>
  );
};
