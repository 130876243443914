import React from 'react';
import classNames from 'classnames';
import { AspectRatioBox, AspectRatioBoxProps } from '@moda/om';
import './PlaceholderCell.scss';

type PlaceholderCellProps = Omit<AspectRatioBoxProps, 'maxHeight' | 'maxWidth'> & {
  invisible?: boolean;
};

export const PlaceholderCell: React.FC<PlaceholderCellProps> = ({
  className,
  invisible,
  ...rest
}) => {
  return (
    <AspectRatioBox
      className={classNames('PlaceholderCell', className, {
        'PlaceholderCell--invisible': invisible
      })}
      maxHeight={540}
      maxWidth={340}
      {...rest}
    />
  );
};
