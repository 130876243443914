import React from 'react';
import { formatDistanceToNow, parseISO, fromUnixTime, formatISO } from 'date-fns';

type Props = React.HTMLAttributes<HTMLTimeElement> & {
  timestamp: string | number;
};

export const RelativeTime: React.FC<Props> = React.memo(({ timestamp, children, ...rest }) => {
  const date = typeof timestamp === 'number' ? fromUnixTime(timestamp) : parseISO(timestamp);
  const iso = formatISO(date);

  return (
    <time dateTime={iso} {...rest}>
      {formatDistanceToNow(date)} {children}
    </time>
  );
});

RelativeTime.displayName = 'RelativeTime';
