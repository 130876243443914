import { gql } from '@apollo/client';
import React, { useCallback, useState } from 'react';
import sha from 'sha.js';
import classNames from 'classnames';
import { Button, TextInput } from '@moda/om';
import { hrefFor } from '../../routers';
import { EmailPumoModuleFormFragment } from '../../generated/types';
import { Link } from '../Link';
import { NormalizePumoStyles, sanitizeHTML } from '../NormalizePumoStyles';
import { useEmailSubscribe } from '../../hooks/useEmailSubscribe';

import './EmailPumoModuleForm.scss';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  emailUnitForm: EmailPumoModuleFormFragment;
}

export const EMAIL_PUMO_MODULE_FORM_FRAGMENT = gql`
  fragment EmailPumoModuleFormFragment on EmailUnit {
    ctaText
    useDivider
    genderType
    eyebrowText {
      body
    }
    headlineText {
      body
    }
    taglineText {
      body
    }
  }
`;

export const EmailPumoModuleForm: React.FC<Props> = ({
  className,
  emailUnitForm: { eyebrowText, headlineText, taglineText, ctaText, useDivider }
}) => {
  const [email, setEmail] = useState('');
  const {
    subscribe,
    isSubscribed,
    isExistingSubscription,
    mailValidationError: error
  } = useEmailSubscribe();

  const handleSubmit = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();

      subscribe({ email, label: 'emailPumoModule' });
    },
    [email, subscribe]
  );

  return (
    <div className={classNames('EmailPumoModuleForm', className)}>
      <div className="EmailPumoModuleForm__text">
        {eyebrowText?.body && (
          <div className="EmailPumoModuleForm__eyebrow">{eyebrowText.body}</div>
        )}

        {headlineText?.body && (
          <div className="EmailPumoModuleForm__headline">{headlineText.body}</div>
        )}

        {useDivider && <hr className="EmailPumoModuleForm__divider" />}

        {taglineText?.body && taglineText?.body !== '' && (
          <NormalizePumoStyles
            className="EmailPumoModuleForm__tagline"
            dangerouslySetInnerHTML={{ __html: `${sanitizeHTML(taglineText.body)}` }}
          />
        )}

        {ctaText && <span className="EmailPumoModuleForm__cta">{ctaText}</span>}
      </div>

      <form className="EmailPumoModuleForm__form" onSubmit={handleSubmit}>
        <TextInput
          disabled={isSubscribed}
          error={Boolean(error)}
          onChange={setEmail}
          placeholder={isSubscribed ? 'Email submitted!' : 'Sign up for our newsletter'}
          required
          type="email"
          value={isSubscribed ? '' : email}
        />

        {isExistingSubscription && (
          <div className="EmailPumoModuleForm__already-subscribed">
            You're already on our newsletter list,{' '}
            <Link
              to={`${hrefFor.UnsubscribePage()}?email_hash=${sha('sha256')
                .update(email)
                .digest('hex')}`}
            >
              click here
            </Link>{' '}
            to manage your subscriptions.
          </div>
        )}

        <Button disabled={isSubscribed} type="submit">
          Submit
        </Button>

        <footer className="EmailPumoModuleForm__opt-out">
          To opt out,{' '}
          <Link className="EmailPumoModuleForm__opt-out-link" to={hrefFor.UnsubscribePage()}>
            unsubscribe here
          </Link>{' '}
          or in our emails.
        </footer>
      </form>
    </div>
  );
};
