import { gql } from '@apollo/client';
import React from 'react';
import { useModuleTracking } from '../../hooks/useModuleTracking';
import { RestrictPlatform } from '../RestrictPlatform';
import { PumoModuleSlide, PUMO_MODULE_SLIDE_FRAGMENT } from '../PumoModuleSlide';
import { EditorialBgColor, EDITORIAL_BG_COLORS } from '../PumoModuleEditorialText';
import { SinglePumoModuleFragment } from '../../generated/types';
import { SwiperCarousel } from '../Swiper';

import './SinglePumoModule.scss';

export interface Props {
  singlePumoModule: SinglePumoModuleFragment;
  beforeEyebrow?: React.ReactNode;
  afterEyebrow?: React.ReactNode;
  normalizePumoStyles?: boolean;
  spaceBetween?: boolean;
  children?: React.ReactNode;
  hideCtaUnderline?: boolean;
}

export const SINGLE_PUMO_MODULE_FRAGMENT = gql`
  fragment SinglePumoModuleFragment on SinglePumoModule {
    id
    name
    bgColor
    id
    platforms: platform
    slides {
      ...PumoModuleSlideFragment
    }
    modelId
    moduleType
  }
  ${PUMO_MODULE_SLIDE_FRAGMENT}
`;

const CUSTOM_COLOR_REGEXP =
  /^(#[a-f0-9]{6}|#[a-f0-9]{3}|rgb *\( *[0-9]{1,3}%? *, *[0-9]{1,3}%? *, *[0-9]{1,3}%? *\)|rgba *\( *[0-9]{1,3}%? *, *[0-9]{1,3}%? *, *[0-9]{1,3}%? *, *[0-9]{1,3}%? *\))$/i;

export const SinglePumoModule: React.FC<Props> = ({
  singlePumoModule: { slides, platforms, bgColor, modelId, moduleType, name, id },
  beforeEyebrow,
  afterEyebrow,
  normalizePumoStyles,
  spaceBetween,
  children,
  hideCtaUnderline
}) => {
  const { ref } = useModuleTracking({ name });

  if (slides.length === 0) return null;

  const backgroundColor = CUSTOM_COLOR_REGEXP.test(bgColor)
    ? bgColor
    : EDITORIAL_BG_COLORS[bgColor as EditorialBgColor];

  return (
    <RestrictPlatform platforms={platforms}>
      <div
        className="SinglePumoModule"
        style={{ backgroundColor }}
        data-model-id={modelId}
        data-module-type={moduleType}
        ref={ref}
      >
        {slides.length > 1 ? (
          <SwiperCarousel
            autoplay={{
              delay: 4000,
              disableOnInteraction: false
            }}
            className="SinglePumoModule__carousel"
            classNameNavigation="SinglePumoModule__nav-buttons"
            classNameWrapper="SinglePumoModule__wrapper"
            identifier={`SinglePumoModule__${id}`}
            loop
            speed={1400}
          >
            {slides.map((slide, index) => (
              <PumoModuleSlide
                key={index}
                slide={slide}
                beforeEyebrow={beforeEyebrow}
                afterEyebrow={afterEyebrow}
                normalizePumoStyles={normalizePumoStyles}
                spaceBetween={spaceBetween}
                index={index + 1}
                hideCtaUnderline={hideCtaUnderline}
              >
                {children}
              </PumoModuleSlide>
            ))}
          </SwiperCarousel>
        ) : (
          <PumoModuleSlide
            slide={slides[0]}
            beforeEyebrow={beforeEyebrow}
            afterEyebrow={afterEyebrow}
            normalizePumoStyles={normalizePumoStyles}
            spaceBetween={spaceBetween}
            hideCtaUnderline={hideCtaUnderline}
          >
            {children}
          </PumoModuleSlide>
        )}
      </div>
    </RestrictPlatform>
  );
};
